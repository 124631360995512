/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export enum DocumentStatus {
    ADDED = 'added',
    UPLOADED = 'uploaded',
    INDEXING = 'indexing',
    COMPLETED = 'completed',
    ERROR = 'error',
    DELETING = 'deleting',
    DELETED = 'deleted',
    UNSUPPORTED = 'unsupported',
}
