import {
    Button,
    Input,
    makeStyles,
    mergeClasses,
    shorthands,
    Subtitle2Stronger,
    tokens,
    Tooltip,
} from '@fluentui/react-components';
import { useEffect, useState } from 'react';
import { Dismiss20, Filter20 } from './BundledIcons';
import { CollapsableListSection } from './CollapsableListSection';
import { ContractDownLeft20Regular, ExpandUpLeft20Regular } from '@fluentui/react-icons';
import { CollapsableListItem } from './CollapsableListItem';
import { MobileSafeButton } from './MobileSafeButton';
import { Breakpoints } from '../../styles';

const useStyles = makeStyles({
    root: {
        display: 'flex',
        flexShrink: 0,
        backgroundColor: tokens.colorNeutralBackground5,
        flexDirection: 'column',
        overflow: 'hidden',
        width: '300px',
        transition: 'width 0.3s ease',
    },
    collapsed: {
        width: '0px',
        overflow: 'visible',
        position: 'relative',
        backgroundColor: tokens.colorNeutralBackground4,
    },
    expanded: {
        width: '300px',
    },
    list: {
        overflowY: 'auto',
        overflowX: 'hidden',
        alignItems: 'stretch',
    },
    header: {
        display: 'flex',
        justifyContent: 'space-between',
        ...shorthands.margin(tokens.spacingVerticalNone, tokens.spacingHorizontalXL),
        alignItems: 'center',
        height: '60px',
    },
    content: {
        flexGrow: 1,
    },
    input: {
        flexGrow: 1,
        backgroundColor: tokens.colorSubtleBackground,
        fontSize: tokens.fontSizeBase400,
    },
    expandButton: {
        position: 'absolute',
        top: '14px',
        left: '10px',
        zIndex: 1,
        ...Breakpoints.small({
            top: '0pc',
            left: '0pc',
        }),
    },
    title: {
        flexGrow: 1,
        fontSize: tokens.fontSizeBase400,
        fontWeight: 'var(--fontWeightSemibold)',
    },
});

interface Section<T> {
    header: string;
    predicate: (item: T) => boolean;
}

type AddItem =
    | { action: () => void; label: string; icon: JSX.Element; type: 'button' }
    | { trigger: JSX.Element; type: 'element' };

interface GenericListProps<T> {
    title: string;
    items: T[];
    renderItem: (item: T) => ReturnType<typeof CollapsableListItem>;
    filterCriteria?: (item: T, searchText: string) => boolean;
    sections?: Array<Section<T>>;
    addItem?: AddItem;
}

export const CollapsableList = <T,>({
    title,
    items,
    renderItem,
    filterCriteria,
    sections,
    addItem,
}: GenericListProps<T>) => {
    const classes = useStyles();
    const [isFiltering, setIsFiltering] = useState(false);
    const [filterText, setFilterText] = useState('');
    const [isCollapsed, setIsCollapsed] = useState<boolean>(false);
    const [filteredItems, setFilteredItems] = useState(items);

    useEffect(() => {
        if (filterCriteria) {
            setFilteredItems(items.filter((item) => filterCriteria(item, filterText)));
        } else {
            setFilteredItems(items);
        }
    }, [items, filterText, filterCriteria]);

    const onSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
        setFilterText(event.target.value);
    };

    const onFilterClick = () => {
        setIsFiltering(true);
    };

    const onFilterCancel = () => {
        setFilterText('');
        setIsFiltering(false);
    };

    const toggleCollapse = () => {
        setIsCollapsed((prev) => !prev);
    };

    const renderSectionedItems = () => {
        if (!sections || sections.length === 0) {
            return filteredItems.map((item) => renderItem(item));
        }

        return sections.map((section) => {
            const sectionItems = filteredItems.filter((item) => section.predicate(item));

            return sectionItems.length > 0 ? (
                <CollapsableListSection
                    key={section.header}
                    header={section.header}
                    items={sectionItems}
                    renderItem={renderItem}
                />
            ) : null;
        });
    };

    return (
        <div className={mergeClasses(classes.root, isCollapsed && classes.collapsed)}>
            {isCollapsed && (
                <div className={classes.expandButton}>
                    <MobileSafeButton
                        icon={<ExpandUpLeft20Regular />}
                        onClick={toggleCollapse}
                        tooltipText="Expand"
                        position="top-left"
                    />
                </div>
            )}

            {!isCollapsed && (
                <div className={classes.content}>
                    <div className={classes.header}>
                        {!isFiltering ? (
                            <>
                                <Subtitle2Stronger className={classes.title}>{title}</Subtitle2Stronger>

                                <Tooltip content="Filter items" relationship="label">
                                    <Button icon={<Filter20 />} appearance="transparent" onClick={onFilterClick} />
                                </Tooltip>

                                {addItem && addItem.type === 'button' && (
                                    <Tooltip content={addItem.label} relationship="label">
                                        <Button icon={addItem.icon} appearance="transparent" onClick={addItem.action} />
                                    </Tooltip>
                                )}

                                {addItem && addItem.type === 'element' && addItem.trigger}

                                <Tooltip content={'Collapse'} relationship="label">
                                    <Button
                                        icon={<ContractDownLeft20Regular />}
                                        appearance="transparent"
                                        onClick={toggleCollapse}
                                    />
                                </Tooltip>
                            </>
                        ) : (
                            <>
                                <Input
                                    placeholder="Filter by name"
                                    className={mergeClasses(classes.input, classes.title)}
                                    onChange={onSearch}
                                    autoFocus
                                />
                                <Button icon={<Dismiss20 />} appearance="transparent" onClick={onFilterCancel} />
                            </>
                        )}
                    </div>

                    <div className={classes.list}>{renderSectionedItems()}</div>
                </div>
            )}
        </div>
    );
};
