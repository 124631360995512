import { Card, Text, Textarea } from '@fluentui/react-components';
import { FC } from 'react';
import { useAdminClasses } from './AdminSettingsView';

interface Props {
    terms: string;
    setTerms: (terms: string) => void;
}

export const AdminSettingsTermsTab: FC<Props> = ({ terms, setTerms }) => {
    const classes = useAdminClasses();

    const handleSetTerms = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        setTerms(e.target.value);
    };

    return (
        <Card className={classes.content}>
            <Text className={classes.subtitle}>Use of Service Acknowledgement</Text>
            <Textarea
                value={terms}
                onChange={handleSetTerms}
                placeholder="Enter terms and conditions"
                rows={10}
                className={classes.textarea}
                maxLength={5000}
            />
        </Card>
    );
};
