import React, { useRef, useState } from 'react';
import { Button, Image, Text, makeStyles, shorthands, tokens, Tooltip } from '@fluentui/react-components';
import { addAlert } from '../../redux/features/app/appSlice';
import { useAppDispatch } from '../../redux/app/hooks';
import { AlertType } from '../../libs/models/AlertType';

const useStyles = makeStyles({
    container: {
        position: 'relative',
        borderRadius: '8px',
        border: `2px dashed ${tokens.colorNeutralStroke1}`,
        ...shorthands.padding(tokens.spacingVerticalXL, tokens.spacingHorizontalL),
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        cursor: 'pointer',
        transition: 'background-color 0.3s ease-in-out, opacity 0.3s ease-in-out',
    },
    outerContainer: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
    },
    dragging: {
        backgroundColor: tokens.colorNeutralBackground4Pressed,
        opacity: 0.6,
    },
    overlay: {
        position: 'absolute',
        inset: 0,
        backgroundColor: tokens.colorNeutralStroke3,
        opacity: 0.4,
        borderRadius: '8px',
        display: 'none',
    },
    overlayVisible: {
        display: 'block',
    },
    image: {
        maxWidth: '100px',
        maxHeight: '100px',
    },
    uploadButton: {
        marginTop: tokens.spacingVerticalM,
    },
});

interface FileDroppableImageProps {
    image: string | undefined;
    acceptedFileTypes: string[];
    maxFileSize: number;
    onFileUpload: (file: File) => void;
    backgroundColor: string;
}

export const FileDropZone: React.FC<FileDroppableImageProps> = ({
    backgroundColor,
    image,
    acceptedFileTypes,
    maxFileSize,
    onFileUpload,
}) => {
    const styles = useStyles();
    const dispatch = useAppDispatch();
    const fileInputRef = useRef<HTMLInputElement>(null);

    const [dragging, setDragging] = useState<boolean>(false);

    const handleFileValidation = (file: File) => {
        if (!acceptedFileTypes.includes(file.type)) {
            dispatch(
                addAlert({ message: 'Invalid file type. Please upload a valid image file.', type: AlertType.Warning }),
            );
            return false;
        }

        if (file.size > maxFileSize) {
            dispatch(
                addAlert({
                    message: `File size should be less than ${maxFileSize / 1024 / 1024} MB.`,
                    type: AlertType.Warning,
                }),
            );
            return false;
        }

        return true;
    };

    const handleFileUpload = (file: File) => {
        if (handleFileValidation(file)) {
            onFileUpload(file);
        }
    };

    const handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
        e.preventDefault();
        setDragging(true);
    };

    const handleDragLeave = () => {
        setDragging(false);
    };

    const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
        e.preventDefault();
        setDragging(false);
        const file = e.dataTransfer.files[0] as File | undefined;
        if (file) {
            handleFileUpload(file);
        }
    };

    return (
        <div className={styles.outerContainer}>
            <div
                className={`${styles.container} ${dragging ? styles.dragging : ''}`}
                onDragOver={handleDragOver}
                onDragLeave={handleDragLeave}
                onDrop={handleDrop}
                onClick={() => fileInputRef.current?.click()}
                style={{ backgroundColor }}
            >
                <input
                    type="file"
                    ref={fileInputRef}
                    style={{ display: 'none' }}
                    accept={acceptedFileTypes.join(',')}
                    onChange={(e) => {
                        const file = e.target.files?.[0];
                        if (file) {
                            handleFileUpload(file);
                        }
                    }}
                />

                {image ? (
                    <Image src={image} alt="Image Preview" className={styles.image} />
                ) : (
                    <Text>Drag & Drop or Click to Upload</Text>
                )}

                <div className={`${styles.overlay} ${dragging ? styles.overlayVisible : ''}`} />
            </div>

            <Tooltip content="Upload an image" relationship="label">
                <Button
                    appearance="secondary"
                    className={styles.uploadButton}
                    onClick={(e) => {
                        e.stopPropagation();
                        fileInputRef.current?.click();
                    }}
                >
                    Upload
                </Button>
            </Tooltip>
        </div>
    );
};
