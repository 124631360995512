/* eslint-disable react-hooks/exhaustive-deps */
import { useMemo } from 'react';
import { TreeNode } from 'react-dropdown-tree-select';
import { useAppSelector } from '../../redux/app/hooks';
import { RootState } from '../../redux/app/store';
import { Collections } from '../../redux/features/collections/CollectionsState';
import { getFormattedRoleName } from '../../components/utils/CollectionUtils';
import { ICollectionState } from '../../redux/features/collections/CollectionState';

interface Props {
    selectedDocumentNodes: string[];
    update?: number;
}

export const useTree = ({ selectedDocumentNodes, update = 0 }: Props) => {
    const { collections } = useAppSelector((state: RootState) => state.collections);

    const keys = useMemo(() => {
        return Object.keys(collections);
    }, [collections]);

    const privateCollections: TreeNode = useMemo(() => {
        const allPrivateCollections = mapCollectionsToTreeNodes(collections, selectedDocumentNodes, (key) => {
            const currentCollection = collections[key];
            return currentCollection.isPrivate;
        });

        return {
            label: 'Private Collections',
            value: 'private-collections',
            expanded: true,
            children: allPrivateCollections,
        };
    }, [collections, selectedDocumentNodes, update]);

    const publicCollections: TreeNode = useMemo(() => {
        const allPublicCollections = mapCollectionsToTreeNodes(collections, selectedDocumentNodes, (key) => {
            const currentCollection = collections[key];
            return !currentCollection.isPrivate && !currentCollection.role;
        });

        return {
            label: 'Public Collections',
            value: 'public-collections',
            expanded: true,
            children: allPublicCollections,
        };
    }, [collections, selectedDocumentNodes, update]);

    const otherCollections: TreeNode[] = useMemo(() => {
        const sortedCollections: TreeNode[] = [];
        const collectionGroups: Array<{ role: string; name: string; collections: ICollectionState[] }> = [];

        Object.keys(collections).forEach((key) => {
            const currentCollection = collections[key];
            if (currentCollection.isPrivate || !currentCollection.role) {
                return;
            }

            const existingCollectionGroup = collectionGroups.find((x) => x.role === currentCollection.role);
            if (!existingCollectionGroup) {
                collectionGroups.push({
                    role: currentCollection.role ?? '',
                    name: getFormattedRoleName(currentCollection.role) ?? '',
                    collections: [currentCollection],
                });
                return;
            }

            existingCollectionGroup.collections.push(currentCollection);
        });

        collectionGroups.forEach((collectionGroup) => {
            const allOtherCollections = collectionGroup.collections
                .map((collection) => mapToCollectionNode(collections, selectedDocumentNodes, collection.id))
                .sort((a, b) => a.label.localeCompare(b.label));

            sortedCollections.push({
                label: `${collectionGroup.name} Collections`,
                value: `${collectionGroup.role}-collections`,
                expanded: true,
                children: allOtherCollections,
            });
        });

        return sortedCollections;
    }, [collections, selectedDocumentNodes, update]);

    const allDocuments: TreeNode[] = useMemo(() => {
        return Object.keys(collections).flatMap((key) => {
            return collections[key].documents.map((document) => ({
                value: `document..${document.id}..${document.collectionId}`,
                label: document.name,
                checked: selectedDocumentNodes.some((x) => x.includes(document.id)),
            }));
        });
    }, [collections, selectedDocumentNodes]);

    const settings = useMemo(() => {
        const collectionIds = selectedDocumentNodes
            .filter((x) => x.startsWith('collection..'))
            .map((x) => x.split('..')[1]);

        const selectedDocuments = selectedDocumentNodes
            .filter((x) => x.startsWith('document..'))
            .map((x) => {
                const splits = x.split('..');
                return {
                    documentId: splits[1],
                    collectonId: splits[2],
                };
            });

        const documentIds: string[] = [];

        selectedDocuments.forEach((selectedDocument) => {
            if (collectionIds.includes(selectedDocument.collectonId)) {
                return;
            }

            documentIds.push(selectedDocument.documentId);
        });

        const isAllPrivateCollectionsSelected = selectedDocumentNodes.find((x) => x === 'private-collections');
        if (isAllPrivateCollectionsSelected) {
            keys.forEach((key) => {
                if (collections[key].isPrivate) {
                    collectionIds.push(key);
                }
            });
        }

        // TODO: Andrew - Handle other types of collections
        otherCollections.forEach((otherCollection) => {
            const isAllOtherCollectionsSelected = selectedDocumentNodes.find((x) => x === otherCollection.value);
            if (isAllOtherCollectionsSelected) {
                keys.forEach((key) => {
                    if (`${collections[key].role}-collections` === otherCollection.value) {
                        collectionIds.push(key);
                    }
                });
            }
        });

        const isAllPublicCollectionsSelected = selectedDocumentNodes.find((x) => x === 'public-collections');
        if (isAllPublicCollectionsSelected) {
            keys.forEach((key) => {
                if (!collections[key].isPrivate) {
                    collectionIds.push(key);
                }
            });
        }

        return {
            collectionIds: collectionIds,
            documentIds: documentIds,
        };
    }, [selectedDocumentNodes, keys, collections]);

    return {
        privateCollections,
        publicCollections,
        otherCollections,
        allCollections: [privateCollections, publicCollections, ...otherCollections],
        allDocuments,
        settings,
    };
};

function mapToCollectionNode(collections: Collections, selectedDocumentNodes: string[], key: string): TreeNode {
    const currentCollection = collections[key];
    const isCollectionChecked = selectedDocumentNodes.some((x) => x.includes(`collection..${key}`));

    const children = currentCollection.documents
        .map((document) => ({
            value: `document..${document.id}..${document.collectionId}`,
            label: document.name,
            checked: isCollectionChecked || selectedDocumentNodes.some((x) => x.includes(document.id)),
        }))
        .sort((a, b) => a.label.localeCompare(b.label));

    return {
        label: `${currentCollection.name} (${currentCollection.documents.length})`,
        value: `collection..${key}`,
        checked: isCollectionChecked,
        expanded: isCollectionChecked || children.some((x) => x.checked),
        children: children,
    };
}

function mapCollectionsToTreeNodes(
    collections: Collections,
    selectedDocumentNodes: string[],
    filterFn: (key: string) => boolean,
): TreeNode[] {
    return Object.keys(collections)
        .filter(filterFn)
        .map((key) => mapToCollectionNode(collections, selectedDocumentNodes, key))
        .sort((a, b) => a.label.localeCompare(b.label));
}
