import { DocumentHeader } from '../../../libs/generated';
import { ICollectionState } from './CollectionState';

export type Collections = Record<string, ICollectionState>;

export interface ICollectionsState {
    collections: Collections;
    selectedId: string;
}

export const initialState: ICollectionsState = {
    collections: {},
    selectedId: '',
};

export interface IUpdateCollectionPayload {
    id: string;
    documents: DocumentHeader[];
}

export interface ICollectionTitleChange {
    id: string;
    newName: string;
}
