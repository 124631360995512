/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { RetrieveSpeechAccessTokenResponse } from '../models/RetrieveSpeechAccessTokenResponse';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class SpeechApiService {
    /**
     * @returns RetrieveSpeechAccessTokenResponse Success
     * @throws ApiError
     */
    public static retrieveSpeechAccessTokenEndpoint(): CancelablePromise<RetrieveSpeechAccessTokenResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/speech/Token',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
            },
        });
    }
}
