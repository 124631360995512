import { FC, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../redux/app/hooks';
import { RootState } from '../../redux/app/store';
import { useRfx } from '../../libs/hooks/useRfx';
import { CollapsableList } from '../shared/CollapsableList';
import { CollapsableListItem } from '../shared/CollapsableListItem';
import { setRfxChat } from '../../redux/features/skills/skillsSlice';
import { AddSquare20Regular, Delete20Regular } from '@fluentui/react-icons';
import { DeleteDialog } from '../shared/DeleteDialog';

export const RfxChatList: FC = () => {
    const { createChat } = useRfx();
    const { chats: conversations, selectedId } = useAppSelector((state: RootState) => state.skills.rfx);

    const [itemBeingDeleted, setItemBeingDeleted] = useState<string | undefined>(undefined);

    const chat = useRfx();
    const dispatch = useAppDispatch();

    const allRfxChats = Object.values(conversations).sort((a, b) => {
        if (a.lastUpdatedTimestamp && b.lastUpdatedTimestamp) {
            return new Date(b.lastUpdatedTimestamp).getTime() - new Date(a.lastUpdatedTimestamp).getTime();
        }
        return 0;
    });

    const handleAdd = async () => {
        const name = 'New Chat';
        await createChat(name);
    };

    const handleDelete = (selectedId: string) => () => {
        const chatId = selectedId;
        void chat.deleteChat(chatId);
    };

    const onEditName = (chatId: string) => async (newName: string) => {
        const oldChat = allRfxChats.find((chat) => chat.id === chatId);
        if (!oldChat) {
            return;
        }

        await chat.updateChat(chatId, newName, oldChat.settings);
    };

    const renderDeleteDialog = () => {
        const chat = allRfxChats.find((chat) => chat.id === itemBeingDeleted);
        if (!chat) {
            return null;
        }

        return (
            <DeleteDialog
                subject={chat.title}
                description={`This action cannot be undone. This will permanently delete the chat and all associated messages.`}
                onConfirm={handleDelete(itemBeingDeleted ?? '')}
                open={!!itemBeingDeleted}
                onClose={() => {
                    setItemBeingDeleted(undefined);
                }}
            />
        );
    };

    return (
        <>
            <CollapsableList
                title="Chats"
                items={allRfxChats}
                renderItem={(chat) => (
                    <CollapsableListItem
                        key={chat.id}
                        id={chat.id}
                        header={chat.title}
                        preview={''}
                        isSelected={chat.id === selectedId}
                        onClick={() => {
                            dispatch(setRfxChat(chat.id));
                        }}
                        onEditName={(newName) => {
                            void onEditName(chat.id)(newName);
                        }}
                        extraActions={[
                            {
                                icon: <Delete20Regular />,
                                label: 'Delete Chat',
                                onClick: () => {
                                    setItemBeingDeleted(chat.id);
                                },
                            },
                        ]}
                    />
                )}
                filterCriteria={(chat, searchText) => chat.title.toLowerCase().includes(searchText.toLowerCase())}
                addItem={{
                    action: () => {
                        void handleAdd();
                    },
                    type: 'button',
                    icon: <AddSquare20Regular />,
                    label: 'Add New Persona',
                }}
            />
            {renderDeleteDialog()}
        </>
    );
};
