import { makeStyles } from '@fluentui/react-components';
import { FC, useMemo } from 'react';
import { useAppSelector } from '../../redux/app/hooks';
import { RootState } from '../../redux/app/store';
import { RfxChatList } from './RfxChatList';
import { useRfx } from '../../libs/hooks/useRfx';
import React from 'react';
import { RfxChatWindow } from './RfxChatWindow';

const useClasses = makeStyles({
    container: {
        overflow: 'hidden',
        display: 'flex',
        flexDirection: 'row',
        alignContent: 'start',
        height: '100%',
    },
});

export const RfxChatView: FC = () => {
    const classes = useClasses();

    const { loadChats } = useRfx();
    const { selectedId, chats: conversations } = useAppSelector((state: RootState) => state.skills.rfx);

    React.useEffect(() => {
        if (Object.keys(conversations).length === 0) {
            void loadChats();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [conversations]);

    const window = useMemo(() => {
        if (selectedId !== '') {
            return <RfxChatWindow key={selectedId} />;
        }

        return null;
    }, [selectedId]);

    return (
        <div className={classes.container}>
            <RfxChatList />
            {window}
        </div>
    );
};
