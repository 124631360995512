import { Card, Tooltip, Text, Button } from '@fluentui/react-components';
import { FC } from 'react';
import { useAdminClasses } from './AdminSettingsView';
import { ArrowUndo16Regular } from '@fluentui/react-icons';
import { useTheme } from '../../libs/hooks/useTheme';
import { initialState } from '../../redux/features/app/AppState';

interface Props {
    style: Record<string, string>;
    setStyle: (style: Record<string, string>) => void;
}

export const AdminSettingsStyleTab: FC<Props> = ({ style, setStyle }) => {
    const classes = useAdminClasses();

    const { updateTheme } = useTheme();

    const brandColorsKeys = Object.keys(style);

    const colors = brandColorsKeys
        .filter((key) => {
            const keyAsNumber = parseInt(key, 10);
            return !isNaN(keyAsNumber);
        })
        .filter((key) => key !== '80' && key !== '70');

    const handleResetBrand = () => {
        setStyle(initialState.serviceInfo.settings.colors);
        updateTheme(initialState.serviceInfo.settings.colors);
    };

    const renderColorGrouping = (key: string, name: string, description: string, color: string) => {
        return (
            <div className={classes.brandColorsContainer}>
                <Text title={description}>{name}</Text>
                <Tooltip content={description} relationship={'label'}>
                    <input
                        className={classes.colorPicker}
                        type="color"
                        value={color}
                        onChange={(event) => {
                            setStyle({ ...style, [key]: event.target.value });
                        }}
                    />
                </Tooltip>
            </div>
        );
    };

    return (
        <Card className={classes.content}>
            <div className={classes.corner}>
                <Tooltip content="Reset all colours to the sites original colour scheme" relationship={'label'}>
                    <Button appearance="transparent" icon={<ArrowUndo16Regular />} onClick={handleResetBrand}>
                        Reset to default
                    </Button>
                </Tooltip>
            </div>

            <div className={classes.group}>
                <Text className={classes.subtitle}>User Chat Bubble</Text>
                <div className={classes.brandColorGrid}>
                    {renderColorGrouping(
                        'chatBubblePrimaryColor',
                        'Primary',
                        "This colour is used for the chat bubble's background.",
                        style.chatBubblePrimaryColor,
                    )}
                    {renderColorGrouping(
                        'chatBubbleSecondaryColor',
                        'Secondary',
                        "This colour is used for the chat bubble's context menu.",
                        style.chatBubbleSecondaryColor,
                    )}
                </div>
            </div>

            <div className={classes.group}>
                <Text className={classes.subtitle}>Main Colours</Text>
                <div className={classes.brandColorGrid}>
                    {renderColorGrouping(
                        '80',
                        'Primary',
                        'This colour is used for buttons, highlight, etc.',
                        style['80'],
                    )}
                    {renderColorGrouping(
                        '70',
                        'Secondary',
                        'This colour is used for navigation, hovered elements, etc.',
                        style['70'],
                    )}
                </div>
            </div>

            <div className={classes.group}>
                <Text className={classes.subtitle}>Accessory Colours</Text>
                <div className={classes.brandColorGrid}>
                    {colors.map((key) => {
                        const { name, description } = brandDescriptions[key];
                        return renderColorGrouping(key, name, description, style[key]);
                    })}
                </div>
            </div>
        </Card>
    );
};

const brandDescriptions: Record<string, { name: string; description: string }> = {
    '10': {
        name: 'Background Lightest',
        description: 'Used for very light backgrounds, cards, or high-contrast elements in dark themes.',
    },
    '20': {
        name: 'Background Light',
        description: 'Typically used for background colours in light themes or secondary panels in dark themes.',
    },
    '30': {
        name: 'Background Accent',
        description: 'Accentuates interactive components such as buttons and inputs in hover states.',
    },
    '40': {
        name: 'Accent Hover',
        description: 'Used for hovering states on buttons or links, providing a noticeable but subtle colour change.',
    },
    '50': {
        name: 'Accent Pressed',
        description:
            'Defines the colour for interactive elements in a pressed state, giving feedback to user interactions.',
    },
    '60': {
        name: 'Interactive',
        description: 'Represents interactive elements like buttons, icons, and links in their base state.',
    },
    '70': {
        name: 'Secondary',
        description:
            'Used for secondary buttons, links, and other interactive elements that are less prominent than primary ones.',
    },
    '80': {
        name: 'Primary',
        description:
            'Core brand colour used for primary buttons, links, and other high-visibility interactive elements.',
    },
    '90': {
        name: 'Accent',
        description: 'Serves as a neutral accent colour for secondary elements, providing subtle contrast.',
    },
    '100': {
        name: 'Subtle Background',
        description: 'For backgrounds with very low contrast, often used in tooltip or modal backdrops.',
    },
    '110': {
        name: 'Border Light',
        description:
            'Used for light borders around interactive elements or to provide separation between UI components.',
    },
    '120': {
        name: 'Border Neutral',
        description: 'Commonly applied to separate sections or create a subtle emphasis around non-primary elements.',
    },
    '130': {
        name: 'Text Neutral',
        description: 'Used for neutral text that complements the primary text without drawing much attention.',
    },
    '140': {
        name: 'Disabled Background',
        description: 'Represents the background for disabled components like buttons, fields, or menu items.',
    },
    '150': {
        name: 'Text Link',
        description: 'Specifies the colour for links, ensuring clear visual distinction from normal text elements.',
    },
    '160': {
        name: 'Dividers',
        description: 'Used to create subtle dividers between sections or define outlines around text and icons.',
    },
};
