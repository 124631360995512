import {
    Label,
    Tooltip,
    Button,
    Accordion,
    AccordionItem,
    AccordionHeader,
    AccordionPanel,
    AccordionToggleEventHandler,
    makeStyles,
    tokens,
    shorthands,
} from '@fluentui/react-components';
import { DocumentArrowUp20Regular, Settings24Regular } from '@fluentui/react-icons';
import { LoadingLabel } from './LoadingLabel';
import { RfxDocumentItem } from './RfxDocumentItem';
import React, { useState, useMemo } from 'react';
import DropdownTreeSelect, { TreeData, TreeNode } from 'react-dropdown-tree-select';
import { useRfx } from '../../libs/hooks/useRfx';
import { AlertType } from '../../libs/models/AlertType';
import { useAppDispatch, useAppSelector } from '../../redux/app/hooks';
import { RootState } from '../../redux/app/store';
import { addAlert } from '../../redux/features/app/appSlice';
import { getErrorDetails } from '../utils/TextUtils';
import { RfxDrawer } from './RfxDrawer';
import { Drawer } from '../shared/Drawer';

const useClasses = makeStyles({
    header: {
        ...shorthands.borderBottom('1px', 'solid', 'rgb(0 0 0 / 10%)'),
        ...shorthands.padding(tokens.spacingVerticalS, tokens.spacingHorizontalXXL),
        backgroundColor: tokens.colorNeutralBackground4,
        display: 'flex',
        flexDirection: 'row',
        boxSizing: 'border-box',
        width: '100%',
        justifyContent: 'space-between',
        height: '50px',
    },
    root: {
        overflowY: 'auto',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        width: '100%',
    },
    buttonNoWrap: {
        whiteSpace: 'nowrap',
        padding: '5px 20px',
        border: '0px',
        outline: 'var(--strokeWidthThin) solid var(--colorNeutralStroke1)',
        borderRadius: '0px',
    },
    title: {
        gap: tokens.spacingHorizontalM,
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        width: '100%',
    },
    titleTabs: {
        gap: tokens.spacingHorizontalM,
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
    },
    main: {
        display: 'flex',
        flexDirection: 'column',
        gap: tokens.spacingVerticalS,
        padding: tokens.spacingHorizontalXXXL,
    },
    buttons: {
        display: 'flex',
        alignSelf: 'end',
        gap: tokens.spacingVerticalS,
    },
    buttonGroup: {
        display: 'flex',
    },
    danger: {
        color: 'red',
    },
    processButton: {
        ...shorthands.margin('0', tokens.spacingHorizontalS, '0', '0'),
        backgroundColor: tokens.colorBrandBackground,
        // text color
        color: tokens.colorNeutralForegroundOnBrand,
        width: '300px',
        paddingTop: '15px',
        paddingBottom: '15px',
        // hover over color
        '&:hover': {
            backgroundColor: tokens.colorBrandBackgroundHover,
        },
    },
    chatWindow: {
        display: 'flex',
        height: '100%',
        width: '100%',
    },
});

interface RfxChatRoomProps {
    isDrawerOpen: boolean;
    setIsDrawerOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export const RfxChatRoom: React.FC<RfxChatRoomProps> = ({ isDrawerOpen, setIsDrawerOpen }) => {
    const classes = useClasses();

    const chat = useRfx();
    const dispatch = useAppDispatch();

    const { collections } = useAppSelector((state: RootState) => state.collections);
    const { chats: conversations, selectedId } = useAppSelector((state: RootState) => state.skills.rfx);

    const [selectedDocumentNodes, setSelectedDocumentNodes] = useState<string[]>([]);
    const [openItems, setOpenItems] = React.useState([0]);

    const conversation = Object.entries(conversations).find((x) => x[0] === selectedId)?.[1];

    const rfxProcessMessages = conversation?.rfxProcessMessages ?? [];
    const rfxProcessResponseMessages = conversation?.rfxProcessResponseMessages ?? [];

    const handleToggle: AccordionToggleEventHandler<number> = (_event, data) => {
        setOpenItems(data.openItems);
    };

    const keys = useMemo(() => {
        return Object.keys(collections);
    }, [collections]);

    const data: TreeData[] = useMemo(() => {
        const allCollections = keys
            .filter((key) => {
                const currentCollection = collections[key];
                return currentCollection.isPrivate;
            })
            .map((key) => {
                const currentCollection = collections[key];

                const isCollectionChecked = selectedDocumentNodes.find((x) => x.includes(`collection..${key}`))
                    ? true
                    : false;

                const children = currentCollection.documents
                    .map((document) => {
                        return {
                            value: `document..${document.id}..${document.collectionId}`,
                            label: document.name,
                            disabled: false,
                            checked:
                                isCollectionChecked || selectedDocumentNodes.find((x) => x.includes(document.id))
                                    ? true
                                    : false,
                        };
                    })
                    .sort((a, b) => {
                        return a.label.localeCompare(b.label);
                    });

                return {
                    label: `${currentCollection.name} (${currentCollection.documents.length})`,
                    value: `collection..${key}`,
                    checked: isCollectionChecked,
                    disabled: true,
                    className: 'collectionNode',
                    // expanded: isCollectionChecked || children.find((x) => x.checked),
                    expanded: true,
                    children: children,
                };
            })
            .sort((a, b) => {
                return a.label.localeCompare(b.label);
            });

        return allCollections;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [collections, keys]);

    const onChange = (_currentNode: TreeNode, selectedNodes: TreeNode[]) => {
        setSelectedDocumentNodes([...selectedNodes.map((x) => x.value)]);
    };

    const onRetrieve = async () => {
        const chatId = selectedId;

        const collectionIds = selectedDocumentNodes
            .filter((x) => x.startsWith('collection..'))
            .map((x) => x.split('..')[1]);

        const selectedDocuments = selectedDocumentNodes
            .filter((x) => x.startsWith('document..'))
            .map((x) => {
                const splits = x.split('..');
                return {
                    documentId: splits[1],
                    collectonId: splits[2],
                };
            });

        const documentIds: string[] = [];

        selectedDocuments.forEach((selectedDocument) => {
            if (collectionIds.includes(selectedDocument.collectonId)) {
                return;
            }

            documentIds.push(selectedDocument.documentId);
        });

        const isAllPrivateCollectionsSelected = selectedDocumentNodes.find((x) => x === 'private-collections');
        if (isAllPrivateCollectionsSelected) {
            keys.forEach((key) => {
                if (collections[key].isPrivate) {
                    collectionIds.push(key);
                }
            });
        }

        const isAllPublicCollectionsSelected = selectedDocumentNodes.find((x) => x === 'public-collections');
        if (isAllPublicCollectionsSelected) {
            keys.forEach((key) => {
                if (!collections[key].isPrivate) {
                    collectionIds.push(key);
                }
            });
        }

        const documentId = documentIds[0];

        await chat.getRfxRequirements(chatId, documentId);
    };

    const handleRetrieval = () => {
        setOpenItems([]);
        onRetrieve()
            .then(() => {
                setOpenItems([0]);
                dispatch(addAlert({ message: 'Retrieved Requirements successfully!', type: AlertType.Success }));
            })
            .catch((e: any) => {
                const errorMessage = `Unable to Retrieve Requirements. Details: ${getErrorDetails(e)}`;
                dispatch(addAlert({ message: errorMessage, type: AlertType.Error }));
            });
    };

    const treeSelect = useMemo(() => {
        return (
            <>
                <Label weight="semibold">Collections and Documents</Label>

                <DropdownTreeSelect
                    texts={{ placeholder: 'Search...' }}
                    data={data}
                    onChange={onChange}
                    keepOpenOnSelect={true}
                    className="document-selector"
                    mode="radioSelect"
                />
            </>
        );
    }, [data]);

    return (
        <div className={classes.chatWindow}>
            <div className={classes.root}>
                <div className={classes.main}>
                    {treeSelect}
                    <Tooltip content="Upload RFP or RFQ document to get requirements" relationship="label">
                        <Button
                            className={classes.processButton}
                            icon={<DocumentArrowUp20Regular />}
                            onClick={handleRetrieval}
                            disabled={selectedDocumentNodes.length === 0 || chat.isLoadingRfxRequirements}
                        >
                            <LoadingLabel
                                isLoading={chat.isLoadingRfxRequirements}
                                loadingText="Retrieving"
                                normalText="Retrieve requirements"
                            />
                        </Button>
                    </Tooltip>
                </div>

                <div className={classes.main}>
                    <Accordion collapsible={true} openItems={openItems} onToggle={handleToggle}>
                        {rfxProcessMessages.toReversed().map((message, index) => (
                            <AccordionItem value={index} key={index}>
                                <AccordionHeader>
                                    <strong>{new Date(message.dateCreated).toLocaleString()}:&nbsp;</strong>
                                    <p>
                                        [{message.rfxCollectionName}/{message.rfxDocumentName}]
                                    </p>
                                </AccordionHeader>
                                <AccordionPanel>
                                    <RfxDocumentItem message={message} responses={rfxProcessResponseMessages} />
                                </AccordionPanel>
                            </AccordionItem>
                        ))}
                    </Accordion>
                </div>
            </div>

            <Drawer
                header={{ icon: <Settings24Regular />, text: 'Settings' }}
                body={<RfxDrawer />}
                open={isDrawerOpen}
                onClose={() => {
                    setIsDrawerOpen(false);
                }}
            />
        </div>
    );
};
