/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CompleteNotificationsRequest } from '../models/CompleteNotificationsRequest';
import type { CreateSharePointCollectionRequest } from '../models/CreateSharePointCollectionRequest';
import type { CreateSharePointCollectionResponse } from '../models/CreateSharePointCollectionResponse';
import type { RetrieveSharePointCollectionResponse } from '../models/RetrieveSharePointCollectionResponse';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class SharePointApiService {
    /**
     * @param requestBody
     * @returns any Success
     * @throws ApiError
     */
    public static completeNotificationsEndpoint(
        requestBody: CompleteNotificationsRequest,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/sharepoint/CompleteNotification',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
            },
        });
    }
    /**
     * @param requestBody
     * @returns CreateSharePointCollectionResponse Success
     * @throws ApiError
     */
    public static createSharePointCollectionEndpoint(
        requestBody: CreateSharePointCollectionRequest,
    ): CancelablePromise<CreateSharePointCollectionResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/sharepoint/Collections',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
            },
        });
    }
    /**
     * @param id
     * @returns void
     * @throws ApiError
     */
    public static deleteSharePointCollectionEndpoint(
        id: string,
    ): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/sharepoint/Collections/{id}',
            path: {
                'id': id,
            },
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
            },
        });
    }
    /**
     * @param id
     * @returns RetrieveSharePointCollectionResponse Success
     * @throws ApiError
     */
    public static retrieveSharePointCollectionEndpoint(
        id: string,
    ): CancelablePromise<RetrieveSharePointCollectionResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/sharepoint/Collections/{id}',
            path: {
                'id': id,
            },
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
            },
        });
    }
    /**
     * @returns any Success
     * @throws ApiError
     */
    public static processNotificationsEndpoint(): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/sharepoint/ProcessNotification',
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
            },
        });
    }
    /**
     * @returns any Success
     * @throws ApiError
     */
    public static receiveNotificationsEndpoint(): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/sharepoint/Notifications',
            errors: {
                400: `Bad Request`,
            },
        });
    }
    /**
     * @returns any Success
     * @throws ApiError
     */
    public static syncFolderStatusEndpoint(): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/sharepoint/SyncFolders',
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
            },
        });
    }
    /**
     * @returns any Success
     * @throws ApiError
     */
    public static syncPermissionsEndpoint(): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/sharepoint/SyncPermissions',
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
            },
        });
    }
    /**
     * @returns any Success
     * @throws ApiError
     */
    public static syncSubscriptionsEndpoint(): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/sharepoint/SyncSubscriptions',
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
            },
        });
    }
}
