import { Middleware } from '@reduxjs/toolkit';
import { RootState, StoreMiddlewareAPI } from './store';

interface PersistMiddlewareConfig {
    sliceName: keyof RootState; // Key of the RootState slice
    propertyPath: string; // Dot-separated path to the property within the slice
    storageKey: string; // Storage key to use for saving the persisted state
}

export const createPersistMiddleware = (config: PersistMiddlewareConfig): Middleware<any, RootState> => {
    return (store: StoreMiddlewareAPI) => (next) => (action) => {
        const result = next(action);

        const sliceState = store.getState()[config.sliceName];

        // Safely access the nested property using a type-checked reducer
        const persistedProperty = config.propertyPath.split('.').reduce<unknown>((state, property) => {
            return state && typeof state === 'object' && property in state
                ? (state as Record<string, unknown>)[property]
                : undefined;
        }, sliceState);

        if (persistedProperty !== undefined) {
            localStorage.setItem(config.storageKey, JSON.stringify(persistedProperty));
        }

        return result;
    };
};
