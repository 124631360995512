import {
    makeStyles,
    // shorthands
} from '@fluentui/react-components';
import { FC } from 'react';
import { useAppSelector } from '../../redux/app/hooks';
import { RootState } from '../../redux/app/store';
import { CollectionWindow } from '../collection/CollectionWindow';
import { CollectionList } from '../collection/collection-list/CollectionList';

const useClasses = makeStyles({
    container: {
        overflow: 'hidden',
        display: 'flex',
        flexDirection: 'row',
        alignContent: 'start',
        height: '100%',
    },
});

export const CollectionsView: FC = () => {
    const classes = useClasses();
    const { selectedId } = useAppSelector((state: RootState) => state.collections);

    return (
        <div className={classes.container}>
            <CollectionList />
            {selectedId !== '' && <CollectionWindow />}
        </div>
    );
};
