import { useMsal } from '@azure/msal-react';
import { getErrorDetails } from '../../components/utils/TextUtils';
import { addAlert, setServiceInfo } from '../../redux/features/app/appSlice';
import { AuthHelper } from '../auth/AuthHelper';
import { AlertType } from '../models/AlertType';
import { ServiceInfoService } from '../services/ServiceInfoService';
import { useAppDispatch, useAppSelector } from '../../redux/app/hooks';

export const useServiceInfo = () => {
    const { instance, inProgress } = useMsal();

    const dispatch = useAppDispatch();
    const serviceInfoService = new ServiceInfoService();
    const { serviceInfo } = useAppSelector((state) => state.app);

    const getServiceInfo = async () => {
        try {
            return await serviceInfoService.retrieveServiceInfoAsync(
                await AuthHelper.getBackendAccessToken(instance, inProgress),
            );
        } catch (e) {
            const errorDetails = getErrorDetails(e);
            const errorMessage = `Error getting service options. Details: ${errorDetails}`;
            dispatch(addAlert({ message: errorMessage, type: AlertType.Error }));
            throw new Error(errorMessage);
        }
    };

    const updateAdminSettings = async (colors: Record<string, string>, images: File[], termsOfUse: string) => {
        try {
            const result = await serviceInfoService.updateAdminSettingsAsync(
                await AuthHelper.getBackendAccessToken(instance, inProgress),
                { colors, files: images, termsOfUse },
            );

            dispatch(setServiceInfo({ ...serviceInfo, settings: result }));

            return result;
        } catch (e) {
            const errorDetails = getErrorDetails(e);
            const errorMessage = `Error updating admin settings. Details: ${errorDetails}`;
            dispatch(addAlert({ message: errorMessage, type: AlertType.Error }));
        }

        return undefined;
    };

    return { getServiceInfo, updateAdminSettings };
};
