import {
    Button,
    Checkbox,
    Table,
    TableBody,
    TableCell,
    TableHeader,
    TableHeaderCell,
    TableRow,
    Tooltip,
    makeStyles,
    shorthands,
    tokens,
} from '@fluentui/react-components';
import { ArrowDownload24Filled, ArrowSyncCircle20Regular } from '@fluentui/react-icons';
import * as React from 'react';
import { useCallback, useState } from 'react';
import { useFile } from '../../libs/hooks';
import { GetResponseOptions } from '../../libs/hooks/useChat';
import { useAppSelector } from '../../redux/app/hooks';
import { RootState } from '../../redux/app/store';
import { SharedStyles } from '../../styles';
import { ChatHistoryItem } from '../chat/chat-history/ChatHistoryItem';
import { CitationDialog } from '../chat/chat-history/dialogs/CitationDialog';
import { timestampToDateString } from '../utils/TextUtils';
import { useRfx } from '../../libs/hooks/useRfx';
import { LoadingLabel } from './LoadingLabel';
import { ChatAuthorType, CitationSource, RfxChatMessageHeader } from '../../libs/generated';

const useClasses = makeStyles({
    root: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        ...shorthands.margin(tokens.spacingVerticalNone, tokens.spacingHorizontalNone),
        // ...shorthands.margin(tokens.spacingVerticalXS),
        ...SharedStyles.scroll,
    },
    noDocumentsText: {
        color: 'red',
        marginBottom: tokens.spacingVerticalXXXL,
    },
    tableTitle: {
        fontWeight: tokens.fontWeightBold,
        color: tokens.colorNeutralForeground1,
    },
    functional: {
        display: 'flex',
        flexDirection: 'row',
        ...shorthands.margin('0', '0', tokens.spacingVerticalS, '0'),
    },
    uploadButton: {
        ...shorthands.margin('0', tokens.spacingHorizontalS, '0', '0'),
        backgroundColor: tokens.colorBrandBackground,
        // text color
        color: tokens.colorNeutralForegroundOnBrand,
        // hover over color
        '&:hover': {
            backgroundColor: tokens.colorBrandBackgroundHover,
        },
    },
    vectorDatabase: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'baseline',
        marginLeft: 'auto',
        ...shorthands.gap(tokens.spacingHorizontalSNudge),
    },
    typingIndicator: {
        maxHeight: '28px',
    },
    table: {
        backgroundColor: tokens.colorNeutralBackground1,
        width: '99%',
    },
    tableHeader: {
        fontWeight: tokens.fontSizeBase600,
        backgroundColor: tokens.colorBrandBackground,
    },
    tableCellNumber: {
        width: '1%',
        textAlign: 'center',
    },
    tableCellRequirement: {
        width: '80%',
    },
    tableCellCitation: {
        width: '20%',
    },
    tableCellSelected: {
        width: '4%',
        textAlign: 'center',
    },
    citation: {
        fontWeight: 500,
        lineHeight: '24px',
        textAlign: 'center',
        ...shorthands.borderRadius('4px'),
        ...shorthands.padding('0px', '8px', '0px', '8px'),
        backgroundColor: '#c4daeb',
        color: '#288bd8',
        ...shorthands.textDecoration('none'),
        cursor: 'pointer',
    },
    chatHistoryItem: {
        marginTop: tokens.spacingVerticalS,
        marginBottom: tokens.spacingVerticalL,
    },
});

interface RfxDocumentItemProps {
    message: RfxChatMessageHeader;
    responses: RfxChatMessageHeader[] | undefined;
}

export const RfxDocumentItem: React.FC<RfxDocumentItemProps> = ({ message, responses }) => {
    const classes = useClasses();
    const { selectedId, chats: conversations } = useAppSelector((state: RootState) => state.skills.rfx);
    const chat = useRfx();
    const file = useFile();
    const [openCitationDialog, setOpenCitationDialog] = useState(false);
    const [activeCitation, setActiveCitation] = useState<CitationSource>();

    const columns = [
        { columnKey: 'number', label: 'No', className: classes.tableCellNumber },
        { columnKey: 'requirement', label: 'Requirement', className: classes.tableCellRequirement },
        // { columnKey: 'citation', label: 'Citation', className: classes.tableCellCitation },
        { columnKey: 'select', label: 'Selected', className: classes.tableCellSelected },
    ];

    const requirements = React.useMemo(() => {
        return message.rfxDocumentBreakdown?.requirements.map((requirement, index) => {
            return { number: index + 1, checked: true, value: requirement };
        });
    }, [message.rfxDocumentBreakdown?.requirements]);

    const [requirementsState, setRequirementsState] = React.useState(requirements);

    const onShowCitation = useCallback(
        (pageNumber: string, snippet: string) => {
            const citation = message.citations.find((_x, i) => i.toString() === pageNumber);

            // Some citations don't match the exact citation path.
            // As a fallback, just check whether a citation for this chat matching the citation source name.
            // if (!citation) {
            //     citation = message.citations?.find((x) => x.sourceFile.includes(citationSourceName));
            // }

            if (citation) {
                const updatedCitation = { ...citation, snippet };
                setActiveCitation(updatedCitation);
                setOpenCitationDialog(true);
            }
        },
        [message.citations],
    );

    const onGettingAnswers = async () => {
        if (requirementsState === undefined || !message.rfxDocumentId) return;

        const chatId = selectedId;
        const documentId = message.rfxDocumentId;
        const notAnsweredRequirements = requirementsState
            .filter((requirement) => requirement.checked)
            .map((requirement) => requirement.value.id)
            .filter((id) => responses?.find((r) => r.rfxDocumentBreakdownItemId === id) === undefined);

        await chat.getRfxResponses(chatId, documentId, message.id, notAnsweredRequirements);
    };

    const handleRGettingAnswers = () => {
        onGettingAnswers()
            .then(() => {
                // dispatch(addAlert({ message: 'Chat settings updated successfully!', type: AlertType.Success }));
            })
            .catch((e: any) => {
                console.log(e);
                // const errorMessage = `Unable to save chat settings. Details: ${getErrorDetails(e)}`;
                // dispatch(addAlert({ message: errorMessage, type: AlertType.Error }));
            });
    };

    function doNothing(options: GetResponseOptions): Promise<void> {
        console.log(options);
        throw new Error('Function not implemented.');
    }

    const getCitationFileName = (pageNumber: string): string => {
        return message.citations.find((_c, i) => i.toString() === pageNumber)?.snippet ?? '';
    };

    const downloadChatHistory = () => {
        const currentChat = conversations[selectedId];
        let content = `Message Created: ${timestampToDateString(message.dateCreated, true)}`;
        content += `\r\nCollection Id: ${message.rfxCollectionId} \r\nCollection Name: ${message.rfxCollectionName}`;
        content += `\r\nDocument Id: ${message.rfxDocumentId} \r\nDocument Name: ${message.rfxDocumentName} \r\n\r\n`;
        content += 'Requirements: \r\n';

        message.rfxDocumentBreakdown?.requirements.forEach((requirement, index) => {
            console.log(message);

            content += '\r\n';

            if (requirement.content) {
                content += '------------------------------------------\r\n';
                content += `${index + 1} - ${requirement.content}`;
            }

            const response = responses?.find((r) => r.rfxDocumentBreakdownItemId === requirement.id);
            if (response?.agentResponse) {
                content += `\r\n\r\n*****Response*****\r\n${response.agentResponse}`;
            }

            content += '------------------------------------------\r\n';
        });

        file.createDownloadableFile(content, `${currentChat.title.replaceAll(' ', '-')}.txt`, 'text/plain');
    };

    const cleanLeadingBullet = (text: string): string => {
        return text.replace(/^[ \t]*[-][ \t]*/, '');
    };

    const collections = conversations[selectedId].settings.collectionIds;
    const documents = conversations[selectedId].settings.documentIds;
    const noDocumentsSelected = collections.length === 0 && documents.length === 0;

    return (
        <div className={classes.root}>
            <div>
                <h3>Document:</h3>
                <p>
                    {message.rfxDocumentName} ({message.rfxCollectionName})
                </p>
                {message.rfxDocumentBreakdown && (
                    <div>
                        {message.rfxDocumentBreakdown.assumptions.length !== 0 && (
                            <>
                                <h3>Assumptions:</h3>
                                <ul>
                                    {message.rfxDocumentBreakdown.assumptions.map((assumption, index) => (
                                        <li key={index}>
                                            {cleanLeadingBullet(assumption.content)}{' '}
                                            {assumption.pageNumber && (
                                                <a
                                                    key={index}
                                                    className={classes.citation}
                                                    title={getCitationFileName(assumption.pageNumber)}
                                                    onClick={() => {
                                                        onShowCitation(assumption.pageNumber, assumption.snippet);
                                                    }}
                                                >
                                                    {getCitationFileName(assumption.pageNumber)}
                                                </a>
                                            )}
                                        </li>
                                    ))}
                                </ul>
                            </>
                        )}
                        {message.rfxDocumentBreakdown.notes.content && (
                            <>
                                <h3>Notes:</h3>
                                <p>
                                    {message.rfxDocumentBreakdown.notes.content}
                                    {message.rfxDocumentBreakdown.notes.pageNumber && (
                                        <a
                                            key={1}
                                            className={classes.citation}
                                            title={getCitationFileName(message.rfxDocumentBreakdown.notes.pageNumber)}
                                            onClick={() => {
                                                onShowCitation(
                                                    message.rfxDocumentBreakdown?.notes.pageNumber ?? '',
                                                    message.rfxDocumentBreakdown?.notes.snippet ?? '',
                                                );
                                            }}
                                        >
                                            {getCitationFileName(message.rfxDocumentBreakdown.notes.pageNumber)}
                                        </a>
                                    )}
                                </p>
                            </>
                        )}
                        <h3>Requirements:</h3>
                        {requirementsState && (
                            <>
                                <Table aria-label="External resource table" className={classes.table}>
                                    <TableHeader>
                                        <TableRow className={classes.tableHeader}>
                                            {columns.map((column) => (
                                                <TableHeaderCell className={column.className} key={column.columnKey}>
                                                    <p className={classes.tableTitle}>{column.label}</p>
                                                </TableHeaderCell>
                                            ))}
                                        </TableRow>
                                    </TableHeader>
                                    <TableBody>
                                        {requirementsState.map((requirement, index) => (
                                            <TableRow key={index}>
                                                <TableCell className={classes.tableCellNumber}>
                                                    {requirement.number}
                                                </TableCell>
                                                <TableCell className={classes.tableCellRequirement}>
                                                    {requirement.value.content}
                                                    {'  '}
                                                    {requirement.value.pageNumber &&
                                                        getCitationFileName(requirement.value.pageNumber) && (
                                                            <a
                                                                key={index}
                                                                className={classes.citation}
                                                                title={getCitationFileName(
                                                                    requirement.value.pageNumber,
                                                                )}
                                                                onClick={() => {
                                                                    onShowCitation(
                                                                        requirement.value.pageNumber,
                                                                        requirement.value.snippet,
                                                                    );
                                                                }}
                                                            >
                                                                {getCitationFileName(requirement.value.pageNumber)}
                                                            </a>
                                                        )}
                                                    <div>
                                                        {(() => {
                                                            // Store the result of find in a variable inside the map function
                                                            const response = responses?.find(
                                                                (r) =>
                                                                    r.rfxDocumentBreakdownItemId ===
                                                                    requirement.value.id,
                                                            );

                                                            const newResponse = response
                                                                ? {
                                                                      ...response,
                                                                      author: ChatAuthorType.AGENT,
                                                                      userId: '',
                                                                      userName: 'bot',
                                                                  }
                                                                : undefined;

                                                            // Render the component only if chatHistoryMessage is not undefined
                                                            return newResponse ? (
                                                                <div className={classes.chatHistoryItem}>
                                                                    <ChatHistoryItem
                                                                        key={index}
                                                                        message={newResponse}
                                                                        messageIndex={index}
                                                                        onSubmit={doNothing}
                                                                        isLatest={false}
                                                                    />
                                                                </div>
                                                            ) : null;
                                                        })()}
                                                    </div>
                                                </TableCell>
                                                <TableCell className={classes.tableCellSelected}>
                                                    <Checkbox
                                                        disabled={
                                                            responses?.find(
                                                                (r) =>
                                                                    r.rfxDocumentBreakdownItemId ===
                                                                    requirement.value.id,
                                                            ) !== undefined
                                                        }
                                                        checked={requirement.checked}
                                                        onChange={() => {
                                                            requirement.checked = !requirement.checked;
                                                            console.log(requirement);
                                                            const newRequirements = [...requirementsState];
                                                            newRequirements[index] = requirement;
                                                            setRequirementsState(newRequirements);
                                                        }}
                                                    />
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                                {requirementsState.filter((requirement) => requirement.checked).length > 0 && (
                                    <>
                                        <div style={{ height: '20px' }} />
                                        <Tooltip
                                            content="Generate Responses based on the selected requirements"
                                            relationship="label"
                                        >
                                            <Button
                                                className={classes.uploadButton}
                                                icon={<ArrowSyncCircle20Regular />}
                                                onClick={handleRGettingAnswers}
                                                disabled={chat.isLoadingRfxResponses || noDocumentsSelected}
                                            >
                                                <LoadingLabel
                                                    isLoading={chat.isLoadingRfxResponses}
                                                    loadingText="Loading"
                                                    normalText="Generate responses"
                                                />
                                            </Button>
                                        </Tooltip>

                                        <Tooltip content="Download Chat History" relationship="label">
                                            <Button
                                                className={classes.uploadButton}
                                                icon={<ArrowDownload24Filled />}
                                                onClick={downloadChatHistory}
                                            >
                                                Download Chat History
                                            </Button>
                                        </Tooltip>

                                        {noDocumentsSelected && (
                                            <p className={classes.noDocumentsText}>
                                                * Navigate to the top of the screen and select one or more documents /
                                                collections to use to generate responses
                                            </p>
                                        )}
                                    </>
                                )}
                            </>
                        )}
                    </div>
                )}
            </div>
            <CitationDialog
                open={openCitationDialog}
                closeDialog={() => {
                    setActiveCitation(undefined);
                    setOpenCitationDialog(false);
                }}
                activeCitation={activeCitation}
            />
        </div>
    );
};
