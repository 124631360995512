import { Stack } from '@fluentui/react';
import {
    AvatarProps,
    Button,
    Divider,
    Persona,
    Text,
    Tooltip,
    makeStyles,
    mergeClasses,
    shorthands,
    tokens,
} from '@fluentui/react-components';
import { Clipboard20Regular, ClipboardTask20Regular } from '@fluentui/react-icons';
import React, { useCallback, useMemo, useState } from 'react';
import { GetResponseOptions } from '../../../libs/hooks/useChat';
import { useAppDispatch, useAppSelector } from '../../../redux/app/hooks';
import { RootState } from '../../../redux/app/store';
import { ActiveUserInfo, DefaultChatUser } from '../../../redux/features/app/AppState';
import { Breakpoints, customTokens } from '../../../styles';
import { timestampToDateString } from '../../utils/TextUtils';
import { TypingIndicator } from '../typing-indicator/TypingIndicator';
import * as utils from './../../utils/TextUtils';
import { ChatHistoryTextContent } from './ChatHistoryTextContent';
import { parseAnswerToHtml } from '../../utils/ChatUtils';
import { addAlert } from '../../../redux/features/app/appSlice';
import { Constants } from '../../../Constants';
import debug from 'debug';
import { AlertType } from '../../../libs/models/AlertType';
import { editChatInput, updateBotResponseStatus } from '../../../redux/features/chats/chatsSlice';
import { CitationDialog } from './dialogs/CitationDialog';
import { ChatAuthorType, ChatMessageHeader, CitationSource } from '../../../libs/generated';
import { ChatBotIcon } from './ChatBotIcon';

const log = debug(Constants.debug.root).extend('chat-history-item');

const useClasses = makeStyles({
    root: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        position: 'relative',
    },
    messageContainer: {
        maxWidth: '75%',
        minWidth: '24em',
        position: 'relative',
    },
    message: {
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        borderRadius: tokens.borderRadiusMedium,
        ...Breakpoints.small({
            maxWidth: '100%',
        }),
        gap: tokens.spacingHorizontalM,
        position: 'relative',
    },
    debug: {
        position: 'absolute',
        top: '-4px',
        right: '-4px',
    },
    alignEnd: {
        alignSelf: 'flex-end',
    },
    persona: {
        marginTop: '6px',
    },
    item: {
        backgroundColor: tokens.colorNeutralBackground1,
        borderRadius: '10px',
        borderTopLeftRadius: '0px',
        ...shorthands.padding(tokens.spacingVerticalM, tokens.spacingHorizontalM),
        position: 'relative',
    },
    me: {
        backgroundColor: customTokens.colorMeBackground,
        borderTopRightRadius: '0px',
        borderTopLeftRadius: '10px',
        width: '100%',
    },
    time: {
        fontSize: tokens.fontSizeBase200,
        color: '#444',
    },
    header: {
        display: 'flex',
        gap: tokens.spacingHorizontalS,
        flexDirection: 'row',
        justifyContent: 'flex-end',
        position: 'absolute',
        bottom: '-20px',
        right: '-40px',
        borderRadius: '10px',
        padding: '4px',
        paddingLeft: '12px',
        zIndex: 1,
        transition: 'opacity 0.3s ease-in-out',
        opacity: 1,
        backgroundColor: tokens.colorNeutralBackground2,
    },
    headerMe: {
        backgroundColor: customTokens.colorMeBackground2,
    },
    headerHidden: {
        visibility: 'hidden',
        opacity: 0,
    },
    nameAndDate: {
        position: 'relative',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        gap: tokens.spacingHorizontalL,
    },
    canvas: {
        width: '100%',
        textAlign: 'center',
    },
    image: {
        maxWidth: '250px',
    },
    blur: {
        filter: 'blur(5px)',
    },
    citationButton: {
        marginRight: 'auto',
    },
    rlhf: {
        marginLeft: 'auto',
    },
    citationLearnMore: {
        ...shorthands.margin('auto', '5px', 'auto', 'px'),
        fontWeight: 600,
        lineHeight: 'normal',
    },
    citation: {
        fontWeight: 'normal',
        lineHeight: 'normal',
        textAlign: 'center',
        borderRadius: '4px',
        ...shorthands.padding(tokens.spacingVerticalXS, tokens.spacingHorizontalS),
        backgroundColor: 'var(--colorBrandForeground1)',
        color: '#fff',
        ...shorthands.textDecoration('none'),
        cursor: 'pointer',
    },
    divider: {
        minHeight: '24px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyItems: 'center',
    },
    followupQuestionsList: {
        marginTop: '10px',
    },
    followupQuestionLearnMore: {
        marginRight: '5px',
        fontWeight: 500,
        lineHeight: 'normal',
    },
    followupQuestion: {
        lineHeight: 'normal',
        textAlign: 'start',
        borderRadius: '4px',
        ...shorthands.padding(tokens.spacingVerticalXS, tokens.spacingHorizontalS),
        backgroundColor: 'var(--colorPaletteBlueForeground2)',
        color: '#fff',
        fontStyle: 'italic',
        ...shorthands.textDecoration('none'),
        cursor: 'pointer',
    },
    icons: {
        padding: '0px',
    },
    clipboardIcon: {
        height: '16px',
        width: '16px',
    },
    transparentAvatar: {
        '& > :first-child': {
            backgroundColor: 'transparent',
        },
    },
});

interface ChatHistoryItemProps {
    message: ChatMessageHeader;
    messageIndex: number;
    isLatest: boolean;
    onSubmit: (options: GetResponseOptions) => Promise<void>;
}

export const ChatHistoryItem: React.FC<ChatHistoryItemProps> = ({ message, messageIndex, isLatest, onSubmit }) => {
    const classes = useClasses();
    const dispatch = useAppDispatch();

    const { selectedId } = useAppSelector((state: RootState) => state.chat);
    const { activeUserInfo } = useAppSelector((state: RootState) => state.app);

    const [visibleHeader, setVisibleHeader] = useState<boolean>(false);
    const [openCitationDialog, setOpenCitationDialog] = useState(false);
    const [activeCitation, setActiveCitation] = useState<CitationSource>();

    const isDefaultUser = message.userId === DefaultChatUser.id;

    const isMe =
        isDefaultUser ||
        (message.author.toLowerCase() !== ChatAuthorType.AGENT.toLowerCase() && message.userId === activeUserInfo?.id);

    const isBot = message.author.toLowerCase() === ChatAuthorType.AGENT.toLowerCase();

    const user: ActiveUserInfo = useMemo(() => {
        if (isDefaultUser) {
            return DefaultChatUser;
        }

        if (message.userName === `${selectedId}-bot` || message.userName.toLocaleLowerCase() === 'bot')
            return Constants.bot.profile as ActiveUserInfo;

        return {
            id: message.userName,
            username: activeUserInfo?.username ?? message.userName,
            email: activeUserInfo?.email ?? '',
            photoUrl: activeUserInfo?.photoUrl,
        };
    }, [
        activeUserInfo?.email,
        activeUserInfo?.photoUrl,
        activeUserInfo?.username,
        isDefaultUser,
        message.userName,
        selectedId,
    ]);

    const [messagedCopied, setMessageCopied] = useState(false);

    const onShowCitation = useCallback(
        (citationSourceName: string) => {
            let citation = message.citations.find((x) => x.sourceFile === citationSourceName);

            // Some citations don't match the exact citation path.
            // As a fallback, just check whether a citation for this chat matching the citation source name.
            if (!citation) {
                citation = message.citations.find((x) => x.sourceFile.includes(citationSourceName));
            }

            if (citation) {
                setActiveCitation(citation);
                setOpenCitationDialog(true);
            }
        },
        [message.citations],
    );

    const parsedAnswer = useMemo(
        () => parseAnswerToHtml(message.agentResponse, onShowCitation),
        [message, onShowCitation],
    );

    const copyOnClick = async () => {
        const text = message.agentResponse?.trim() === '' ? message.userPrompt : parsedAnswer.answerText;
        await navigator.clipboard.writeText(text).then(() => {
            setMessageCopied(true);
            setTimeout(() => {
                setMessageCopied(false);
            }, 2000);
        });
    };

    const onFollowUpQuestionClicked = (value: string) => {
        if (value.trim() === '') {
            return; // only submit if value is not empty
        }

        dispatch(editChatInput({ id: selectedId, newInput: '' }));
        dispatch(updateBotResponseStatus({ chatId: selectedId, status: 'Generating response' }));
        onSubmit({ value, chatId: selectedId }).catch((error) => {
            const message = `Error submitting chat input: ${(error as Error).message}`;
            log(message);
            dispatch(
                addAlert({
                    type: AlertType.Error,
                    message,
                }),
            );
        });
    };

    const avatarTemp: AvatarProps = isBot
        ? { icon: <ChatBotIcon />, color: 'platinum', className: classes.transparentAvatar }
        : isDefaultUser
          ? { idForColor: selectedId, color: 'colorful' }
          : { name: user.username, color: 'colorful', image: { src: user.photoUrl } };

    const avatar = { ...avatarTemp, style: { margin: '0px' } };

    const content =
        isBot && message.agentResponse?.length === 0 ? (
            <TypingIndicator />
        ) : (
            <ChatHistoryTextContent userPrompt={message.userPrompt} agentResponse={parsedAnswer.answerHtml} />
        );

    const copyButton = (
        <Tooltip content={messagedCopied ? 'Copied' : 'Copy answer'} relationship="label">
            <Button
                icon={
                    messagedCopied ? (
                        <ClipboardTask20Regular className={classes.clipboardIcon} />
                    ) : (
                        <Clipboard20Regular className={classes.clipboardIcon} />
                    )
                }
                appearance="transparent"
                className={classes.icons}
                onClick={() => {
                    void copyOnClick();
                }}
            />
        </Tooltip>
    );

    const shouldShowHeader = !visibleHeader && (!isLatest || isMe);

    return (
        <div className={classes.root}>
            <div className={mergeClasses(classes.messageContainer, isMe && classes.alignEnd)}>
                <div
                    className={classes.message}
                    data-testid={`chat-history-item-${messageIndex}`}
                    data-username={user.username}
                    data-content={utils.formatChatTextContent(message.agentResponse)}
                >
                    {!isMe && <Persona className={classes.persona} avatar={avatar} />}

                    <div
                        onMouseOver={() => {
                            setVisibleHeader(true);
                        }}
                        onMouseLeave={() => {
                            setVisibleHeader(false);
                        }}
                        className={isMe ? mergeClasses(classes.item, classes.me) : classes.item}
                    >
                        {content}

                        <div
                            className={mergeClasses(
                                mergeClasses(classes.header, isMe && classes.headerMe),
                                shouldShowHeader && classes.headerHidden,
                            )}
                        >
                            <Text className={classes.time}>{timestampToDateString(message.dateCreated, true)}</Text>

                            {copyButton}
                        </div>

                        {!!parsedAnswer.citations.length && (
                            <Stack.Item>
                                <div className={classes.divider}>
                                    <Divider />
                                </div>
                                <Stack horizontal wrap tokens={{ childrenGap: 5 }}>
                                    <span className={classes.citationLearnMore}>Citations:</span>
                                    {parsedAnswer.citations.map((x, i) => {
                                        let fileName = x.split('/')[2];
                                        if (!fileName) {
                                            fileName = x.split('/')[1];
                                        }

                                        if (!fileName) {
                                            fileName = x;
                                        }

                                        return (
                                            <a
                                                key={i}
                                                className={classes.citation}
                                                title={fileName}
                                                onClick={() => {
                                                    onShowCitation(x);
                                                }}
                                            >
                                                {`${++i}. ${fileName}`}
                                            </a>
                                        );
                                    })}
                                </Stack>
                            </Stack.Item>
                        )}

                        {!!parsedAnswer.followupQuestions.length && (
                            <Stack.Item>
                                <div className={classes.divider}>
                                    <Divider />
                                </div>
                                <Stack
                                    horizontal
                                    wrap
                                    className={!!parsedAnswer.citations.length ? classes.followupQuestionsList : ''}
                                    tokens={{ childrenGap: 6 }}
                                >
                                    <span className={classes.followupQuestionLearnMore}>Follow-up questions:</span>
                                    {parsedAnswer.followupQuestions.map((x, i) => {
                                        return (
                                            <a
                                                key={i}
                                                className={classes.followupQuestion}
                                                title={x}
                                                onClick={() => {
                                                    onFollowUpQuestionClicked(x);
                                                }}
                                            >
                                                {x}
                                            </a>
                                        );
                                    })}
                                </Stack>
                            </Stack.Item>
                        )}
                    </div>

                    {isMe && <Persona className={classes.persona} avatar={avatar} />}

                    <CitationDialog
                        open={openCitationDialog}
                        closeDialog={() => {
                            setActiveCitation(undefined);
                            setOpenCitationDialog(false);
                        }}
                        activeCitation={activeCitation}
                    />
                </div>
            </div>
        </div>
    );
};
