import { FC, useEffect, useState } from 'react';

interface Props {
    isLoading: boolean;
    loadingText: string;
    normalText: string;
}

export const LoadingLabel: FC<Props> = ({ isLoading, loadingText, normalText }) => {
    const [dots, setDots] = useState<string>('');

    useEffect(() => {
        const interval = setInterval(() => {
            if (dots.length === 3) {
                setDots('');
            } else {
                setDots(dots + '.');
            }
        }, 1000);

        return () => {
            clearInterval(interval);
        };
    }, [dots]);

    return <>{isLoading ? loadingText + dots : normalText}</>;
};
