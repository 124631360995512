import { Button } from '@fluentui/react-button';
import { DialogOpenChangeData, Input, makeStyles } from '@fluentui/react-components';
import {
    Dialog,
    DialogActions,
    DialogBody,
    DialogContent,
    DialogSurface,
    DialogTitle,
    DialogTrigger,
} from '@fluentui/react-dialog';
import { useMemo, useState } from 'react';
import { useSharePointCollection } from '../../../../libs/hooks/useSharePointCollection';

const useClasses = makeStyles({
    dialog: {
        width: '100%',
    },
    dialogContent: {
        maxHeight: '35rem',
        width: '100%',
    },
    actions: {
        paddingTop: '10%',
    },
    input: {
        width: '100%',
    },
});

interface ICreateSharePointCollectionConfirmationDialogProps {
    open: boolean;
    onConfirm: () => void;
    onClose: () => void;
}

export const CreateSharePointCollectionConfirmationDialog: React.FC<
    ICreateSharePointCollectionConfirmationDialogProps
> = ({ open, onConfirm, onClose }) => {
    const [sharePointUrl, setSharePointUrl] = useState<string>('');

    const classes = useClasses();
    const sharePointCollection = useSharePointCollection();

    const onConfirmUpload = () => {
        void sharePointCollection.createCollection(sharePointUrl);
        onConfirm();
    };

    const isValidUrl = useMemo(() => {
        return sharePointUrl.trim().length > 0;
    }, [sharePointUrl]);

    return (
        <Dialog
            open={open}
            onOpenChange={(_ev: any, data: DialogOpenChangeData) => {
                if (!data.open) onClose();
            }}
        >
            <DialogSurface>
                <DialogBody className={classes.dialog}>
                    <DialogTitle>Acknowledge SharePoint Connection</DialogTitle>

                    <DialogContent className={classes.dialogContent}>
                        <p>
                            Please <strong>do NOT upload PROTECTED documents</strong>.
                        </p>
                        <div>
                            <Input
                                className={classes.input}
                                placeholder="https://CompanyName.sharepoint.com/sites/SiteName"
                                value={sharePointUrl}
                                onChange={(_ev, value) => {
                                    setSharePointUrl(value.value);
                                }}
                            />
                        </div>
                    </DialogContent>

                    <DialogActions className={classes.actions}>
                        <DialogTrigger action="close" disableButtonEnhancement>
                            <Button appearance="secondary">Cancel</Button>
                        </DialogTrigger>

                        <DialogTrigger action="close" disableButtonEnhancement>
                            <Button appearance="primary" onClick={onConfirmUpload} disabled={!isValidUrl}>
                                Acknowledge
                            </Button>
                        </DialogTrigger>
                    </DialogActions>
                </DialogBody>
            </DialogSurface>
        </Dialog>
    );
};
