import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../redux/app/hooks';
import { setChatIcon, setLogo, setTheme } from '../../redux/features/theme/themeSlice';
import { lightTheme } from '../../styles';

export const useTheme = () => {
    const dispatch = useAppDispatch();

    const logoKey = 'logo';
    const chatIconKey = 'chatLogo';

    const { colors, images } = useAppSelector((state) => state.app.serviceInfo.settings);
    const { theme, chatIcon, logo } = useAppSelector((state) => state.theme);

    const updateTheme = (style: Record<string, string>) => {
        dispatch(setTheme(lightTheme(style)));
    };

    useEffect(() => {
        dispatch(setTheme(lightTheme(colors)));
    }, [dispatch, colors]);

    useEffect(() => {
        const chatIcon = images[chatIconKey];
        if (chatIcon) {
            dispatch(setChatIcon(chatIcon));
        }
    }, [dispatch, images]);

    useEffect(() => {
        const logo = images[logoKey];
        if (logo) {
            dispatch(setLogo(logo));
        }
    }, [dispatch, images]);

    return {
        theme,
        logo: logo ?? undefined,
        logoKey,
        chat: chatIcon ?? undefined,
        chatIconKey,
        updateTheme,
    };
};
