import {
    makeStyles,
    tokens,
    Label,
    Input,
    Textarea,
    Text,
    Button,
    shorthands,
    Tooltip,
    Checkbox,
    CheckboxOnChangeData,
    Badge,
} from '@fluentui/react-components';
import React, { useEffect, useRef, useState } from 'react';
import { RootState } from '../../redux/app/store';
import { useAppDispatch, useAppSelector } from '../../redux/app/hooks';
import { Alerts } from '../shared/Alerts';
import { usePersona } from '../../libs/hooks';
import { setSelectedViewPersonaById } from '../../redux/features/personas/personasSlice';
import { addAlert } from '../../redux/features/app/appSlice';
import { AlertType } from '../../libs/models/AlertType';
import { PersonaHeader, PersonaStatus } from '../../libs/generated';
import { Info16Regular, Save20Regular } from '@fluentui/react-icons';
import { Breakpoints } from '../../styles';

const useClasses = makeStyles({
    personasWindow: {
        width: '100%',
        height: 'calc(100% - 40px)', // Temporary until alerts are positioned absolutely
        overflowY: 'auto',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        backgroundColor: 'var(--colorNeutralBackground4)',
        boxShadow: 'rgba(0, 0, 0, 0.25) 0px 0.2rem 0.4rem -0.075rem',
        paddingTop: '40px',
    },
    personasContent: {
        width: '60%',
        height: '100%',
        display: 'flex',
        flexDirection: 'row',
        gap: '40px',
        ...Breakpoints.large({
            width: '90%',
        }),
    },
    content: {
        display: 'flex',
        flexDirection: 'column',
        rowGap: '15px',
        ...shorthands.margin(tokens.spacingVerticalXL, tokens.spacingHorizontalNone),
    },
    dialogTitle: {
        fontSize: '24px',
        fontWeight: 600,
        height: '24px',
    },
    alerts: {
        position: 'absolute',
        bottom: '20px',
        right: '20px',
        maxWidth: '400px',
        gap: '10px',
    },
    personasForm: {
        flex: 3,
    },
    header: {
        display: 'flex',
        flexDirection: 'column',
        gap: '10px',
    },
    headingWithActions: {
        display: 'flex',
        width: '100%',
        justifyContent: 'space-between',
        gap: '10px',
    },
    labelRow: {
        display: 'flex',
        justifyContent: 'space-between',
    },
    labelInline: {
        display: 'flex',
    },
    personaText: {
        marginLeft: '10px',
    },
    redText: {
        color: 'red',
    },
    supportingLabelText: {
        fontSize: '12px',
        color: '#665',
    },
    formLabel: {
        margin: 'auto 0',
    },
    default: {
        verticalAlign: 'middle',
        color: '#333',
    },
});

export const PersonaWindow: React.FC = () => {
    const classes = useClasses();
    const { personas, selectedViewPersonaId } = useAppSelector((state: RootState) => state.personas);

    const dispatch = useAppDispatch();
    const persona = usePersona();

    const [formData, setFormData] = useState<PersonaHeader>({
        id: '',
        name: '',
        description: '',
        message: '',
        isDefault: false,
        requiresDocuments: false,
        status: PersonaStatus.ACTIVE,
        createdTimestamp: '',
        modifiedTimestamp: '',
    });

    const textAreaRef = useRef<HTMLTextAreaElement>(null);

    useEffect(() => {
        if (!!selectedViewPersonaId) {
            setFormData(personas[selectedViewPersonaId]);
            return;
        }

        const activePersonas = Object.values(personas).filter((p) => p.status === PersonaStatus.ACTIVE);
        if (!activePersonas.length) {
            return;
        }

        const persona = activePersonas[activePersonas.length - 1];
        dispatch(setSelectedViewPersonaById(persona.id));
        setFormData(persona);
    }, [selectedViewPersonaId, personas, dispatch]);

    useEffect(() => {
        resizeTextArea(textAreaRef.current);
    }, [formData]);

    const resizeTextArea = (textArea: HTMLInputElement | HTMLTextAreaElement | null) => {
        if (!textArea) {
            return;
        }

        textArea.style.height = 'auto';
        textArea.style.height = textArea.scrollHeight + 'px';
    };

    const handleInputEditChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const { name, value } = e.target;
        console.log(formData.requiresDocuments);
        console.log(value);
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleCheckboxEditChange = (e: React.ChangeEvent<HTMLInputElement | CheckboxOnChangeData>) => {
        const { checked } = e.target;
        console.log(checked);
        setFormData({
            ...formData,
            ['requiresDocuments']: checked ? true : false,
        });
    };

    const handleTextAreaInputEditChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        resizeTextArea(e.target);
        handleInputEditChange(e);
    };

    const savePersona = () => {
        if (!selectedViewPersonaId) {
            return;
        }

        if (formData.name.trim() === '') {
            const message = `Failed to save. Persona name must not be empty.`;
            dispatch(
                addAlert({
                    type: AlertType.Error,
                    message,
                }),
            );
            return;
        }
        if (formData.description.trim() === '') {
            const message = `Failed to save. Persona description must not be empty.`;
            dispatch(
                addAlert({
                    type: AlertType.Error,
                    message,
                }),
            );
            return;
        }
        if (formData.message.length < minMessageCharacters) {
            const message = `Failed to save. Persona message is not long enough. It must be at least ${minMessageCharacters} characters long. It is currently ${formData.message.length} characters long.`;
            dispatch(
                addAlert({
                    type: AlertType.Error,
                    message,
                }),
            );
            return;
        }

        // TODO: When this fails, it shows the success message still. Need to handle promises properly.
        void persona.updatePersona(
            selectedViewPersonaId,
            formData.name,
            formData.description,
            formData.message,
            formData.requiresDocuments,
        );

        const message = 'Persona successfully saved';

        dispatch(
            addAlert({
                type: AlertType.Success,
                message,
            }),
        );
    };

    const minMessageCharacters = 100;

    const CounterText = () => {
        return (
            <Text className={classes.redText}>
                {formData.message.length} / {minMessageCharacters} characters.{' '}
                {minMessageCharacters - formData.message.length} characters left.
            </Text>
        );
    };

    return (
        <div className={classes.personasWindow}>
            <div className={classes.personasContent}>
                <div className={classes.personasForm}>
                    <div className={classes.header}>
                        <div className={classes.headingWithActions}>
                            <Text className={classes.dialogTitle}>
                                {formData.name}{' '}
                                {formData.isDefault && (
                                    <Badge appearance="filled" className={classes.default}>
                                        Default
                                    </Badge>
                                )}
                            </Text>
                            <Button type="submit" onClick={savePersona} appearance="secondary" icon={<Save20Regular />}>
                                Save
                            </Button>
                        </div>
                        <Text className={classes.supportingLabelText}>
                            Personas are fully customizable representations of personalities used to control the AI
                            behaviours.
                        </Text>
                    </div>
                    <div className={classes.content}>
                        <div className={classes.labelRow}>
                            <Label className={classes.formLabel} weight="semibold" required htmlFor={'name-input-edit'}>
                                Name
                            </Label>
                            <Tooltip
                                content={{
                                    children: 'A short name for the persona.',
                                }}
                                positioning="above-start"
                                withArrow
                                relationship="label"
                            >
                                <Info16Regular tabIndex={0} />
                            </Tooltip>
                        </div>
                        <Input
                            required
                            name="name"
                            type="text"
                            id={'name-input-edit'}
                            value={formData.name}
                            onChange={handleInputEditChange}
                        />
                        <div className={classes.labelRow}>
                            <Label
                                className={classes.formLabel}
                                weight="semibold"
                                required
                                htmlFor={'description-input-edit'}
                            >
                                Description
                            </Label>{' '}
                            <Tooltip
                                content={{
                                    children:
                                        'A short summary of the personas intent. This text does not change the personas behaviour.',
                                }}
                                positioning="above-start"
                                withArrow
                                relationship="label"
                            >
                                <Info16Regular tabIndex={0} />
                            </Tooltip>
                        </div>
                        <Input
                            required
                            name="description"
                            type="text"
                            value={formData.description}
                            onChange={handleInputEditChange}
                        />
                        <div className={classes.labelRow}>
                            <Label
                                className={classes.formLabel}
                                weight="semibold"
                                required
                                htmlFor={'persona-message-input-edit'}
                            >
                                Message
                            </Label>
                            <Tooltip
                                content={{
                                    children:
                                        'Ensure Persona messages are sufficiently detailed with the intended behaviour and rules.',
                                }}
                                positioning="above-start"
                                withArrow
                                relationship="label"
                            >
                                <Info16Regular tabIndex={0} />
                            </Tooltip>
                        </div>
                        <Textarea
                            ref={textAreaRef}
                            required
                            name="message"
                            resize="vertical"
                            rows={5}
                            id={'persona-message-input-edit'}
                            value={formData.message}
                            onChange={handleTextAreaInputEditChange}
                        />
                        {formData.message.length < minMessageCharacters && <CounterText />}

                        <div className={classes.labelInline}>
                            <Label className={classes.formLabel} weight="semibold">
                                Are documents needed for this persona to work correctly?
                            </Label>
                            <Checkbox checked={formData.requiresDocuments} onChange={handleCheckboxEditChange} />
                        </div>
                    </div>
                </div>
            </div>
            <div className={classes.alerts}>
                <Alerts />
            </div>
        </div>
    );
};
