import { Button } from '@fluentui/react-button';
import { DialogOpenChangeData, makeStyles } from '@fluentui/react-components';
import {
    Dialog,
    DialogActions,
    DialogBody,
    DialogContent,
    DialogSurface,
    DialogTitle,
    DialogTrigger,
} from '@fluentui/react-dialog';

const useClasses = makeStyles({
    root: {
        width: '450px',
    },
    actions: {
        paddingTop: '10%',
    },
});

interface DeleteDialogProps {
    subject: string;
    description: string;
    onConfirm: () => void;
    open: boolean;
    onClose: () => void;
    children?: JSX.Element;
}

export const DeleteDialog: React.FC<DeleteDialogProps> = ({
    subject,
    description,
    onConfirm,
    open,
    onClose,
    children,
}) => {
    const classes = useClasses();

    const body = (
        <DialogBody>
            <DialogTitle>Are you sure you want to delete {subject}?</DialogTitle>

            <DialogContent>{description}</DialogContent>

            <DialogActions className={classes.actions}>
                <DialogTrigger action="close" disableButtonEnhancement>
                    <Button appearance="secondary">Cancel</Button>
                </DialogTrigger>

                <DialogTrigger action="close" disableButtonEnhancement>
                    <Button appearance="primary" onClick={onConfirm}>
                        Delete
                    </Button>
                </DialogTrigger>
            </DialogActions>
        </DialogBody>
    );

    return (
        <Dialog
            open={open}
            onOpenChange={(_ev: any, data: DialogOpenChangeData) => {
                if (!data.open) onClose();
            }}
        >
            <DialogTrigger action="open">{children}</DialogTrigger>
            <DialogSurface className={classes.root}>{body}</DialogSurface>
        </Dialog>
    );
};
