import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Theme } from '@fluentui/react-components';
import { IThemeState } from './themeState';

const initialState: IThemeState = {
    theme: null,
    logo: null,
    chatIcon: null,
};

const themeSlice = createSlice({
    name: 'theme',
    initialState,
    reducers: {
        setTheme: (state, action: PayloadAction<Theme>) => {
            state.theme = action.payload;
        },
        setLogo: (state, action: PayloadAction<string>) => {
            state.logo = action.payload;
        },
        setChatIcon: (state, action: PayloadAction<string>) => {
            state.chatIcon = action.payload;
        },
    },
});

export const { setTheme, setLogo, setChatIcon } = themeSlice.actions;

export default themeSlice.reducer;
