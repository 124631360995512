import { makeStyles } from '@fluentui/react-components';
import { FC } from 'react';
import { WillWindow } from './WillWindow';

const useClasses = makeStyles({
    container: {
        overflow: 'hidden',
        display: 'flex',
        flexDirection: 'row',
        alignContent: 'start',
        height: '100%',
    },
});

export const WillsView: FC = () => {
    const classes = useClasses();

    return (
        <div className={classes.container}>
            <WillWindow />
        </div>
    );
};
