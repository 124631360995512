import { renderToStaticMarkup } from 'react-dom/server';
import { BackendServiceUrl } from '../../libs/services/BaseService';
import { IHtmlParsedAnswer } from '../../redux/features/chats/ChatState';

export function parseAnswerToHtml(
    answer: string | null | undefined,
    onCitationClicked: (citationFilePath: string) => void,
): IHtmlParsedAnswer {
    const citations: string[] = [];
    const followUpQuestions: string[] = [];

    // Extract any follow-up questions that might be in the answer
    let parsedAnswer =
        answer?.replace(/<<([^>>]+)>>/g, (_, content: string) => {
            followUpQuestions.push(content);
            return '';
        }) ?? '';

    // trim any whitespace from the end of the answer after removing follow-up questions
    parsedAnswer = parsedAnswer.trim();

    // Omit a citation that is still being typed during streaming
    let lastIndex = parsedAnswer.length;
    for (let i = parsedAnswer.length - 1; i >= 0; i--) {
        if (parsedAnswer[i] === ']') {
            break;
        } else if (parsedAnswer[i] === '[') {
            lastIndex = i;
            break;
        }
    }
    const truncatedAnswer = parsedAnswer.substring(0, lastIndex);
    parsedAnswer = truncatedAnswer;

    const parts = parsedAnswer.split(/\[([^\]]+)\]/g);

    const fragments: string[] = parts.map((part, index) => {
        if (index % 2 === 0) {
            return part;
        }

        let citationIndex: number;
        if (!citations.includes(part)) {
            citations.push(part);
            citationIndex = citations.length;
        } else {
            citationIndex = citations.indexOf(part) + 1;
        }

        const path = getCitationFilePath(part);

        return renderToStaticMarkup(
            <a
                className="supContainer"
                title={part}
                onClick={() => {
                    onCitationClicked(path);
                }}
            >
                <sup>{citationIndex}</sup>
            </a>,
        );
    });

    return {
        answerHtml: fragments.join(''),
        answerText: fragments
            .map((fragment) => fragment.trim())
            .filter((fragment) => !fragment.startsWith('<a'))
            .join(''),
        citations,
        followupQuestions: followUpQuestions,
    };
}

export function getCitationFilePath(citation: string): string {
    return `${BackendServiceUrl}/rootFolders/${citation}`;
}

export enum DrawerState {
    PERSONA = 'Persona',
    DOCUMENTS = 'Documents',
    CLOSED = 'Closed',
    RFX = 'Rfx',
}
