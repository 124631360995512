import { Card, Text, tokens } from '@fluentui/react-components';
import { FileDropZone } from '../shared/FileDropZone';
import { useAdminClasses } from './AdminSettingsView';
import { useTheme } from '../../libs/hooks/useTheme';

interface Props {
    logo: string | undefined;
    chat: string | undefined;
    setLogo: (file: string) => void;
    setChat: (file: string) => void;
    setUploadedLogo: (file: File) => void;
    setUploadedChat: (file: File) => void;
}

export const AdminSettingsImageTab = ({ logo, chat, setLogo, setChat, setUploadedLogo, setUploadedChat }: Props) => {
    const classes = useAdminClasses();

    const { chatIconKey, logoKey } = useTheme();

    const handleImageUpload = (
        file: File | undefined,
        setImage: (file: File) => void,
        setPreview: (file: string) => void,
        name: string,
    ) => {
        if (file) {
            const namedFile = new File([file], name, { type: file.type });

            const previewUrl = URL.createObjectURL(namedFile);
            setImage(namedFile);
            setPreview(previewUrl);
        }
    };

    return (
        <Card className={classes.content}>
            <div className={classes.group}>
                <Text className={classes.subtitle}>Logo</Text>
                <FileDropZone
                    image={logo}
                    onFileUpload={(file) => {
                        handleImageUpload(file, setUploadedLogo, setLogo, logoKey);
                    }}
                    acceptedFileTypes={['image/png', 'image/jpeg', 'image/svg+xml']}
                    maxFileSize={1000000}
                    backgroundColor={tokens.colorBrandForeground2}
                />
            </div>

            <div className={classes.group}>
                <Text className={classes.subtitle}>Chat Logo</Text>
                <FileDropZone
                    image={chat}
                    onFileUpload={(file) => {
                        handleImageUpload(file, setUploadedChat, setChat, chatIconKey);
                    }}
                    acceptedFileTypes={['image/png', 'image/jpeg', 'image/svg+xml']}
                    maxFileSize={1000000}
                    backgroundColor={tokens.colorNeutralBackground4}
                />
            </div>
        </Card>
    );
};
