/// Information about token usage used to generate bot response.
export type TokenUsage = Record<string, number | undefined>;

export type TokenUsageView = Record<string, ITokenUsageViewDetails>;

export interface ITokenUsageViewDetails {
    usageCount: number;
    legendLabel: string;
    color: string;
}

export interface IFunctionDetails {
    usageCount: number;
    legendLabel: string;
    color?: string;
}

export const TokenUsageFunctionNameMap: Record<string, string> = {
    metaPromptTemplate: 'Meta Prompt Template',
    sources: 'Sources',
    userQuestion: 'User Question',
    userIntentExtraction: 'User Intent Extraction',
    promptInput: 'Prompt Input',
    promptOutput: 'Prompt Output',
};
