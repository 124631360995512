import { Button } from '@fluentui/react-button';
import { DialogOpenChangeData, makeStyles } from '@fluentui/react-components';
import {
    Dialog,
    DialogActions,
    DialogBody,
    DialogContent,
    DialogSurface,
    DialogTitle,
    DialogTrigger,
} from '@fluentui/react-dialog';
import { Info20Filled } from '@fluentui/react-icons';

const useClasses = makeStyles({
    dialog: {},
    root: {
        maxWidth: '600px',
    },
    dialogContent: {
        // reduce a little the size of the content height for better sizing
        maxHeight: '35rem',
        paddingRight: '2.5rem',
    },
    actions: {
        paddingTop: '10%',
    },
    rowContent: {
        display: 'flex',
        alignItems: 'center',
        gap: '10px',
    },
});

interface IUploadDocumentConfirmationDialogProps {
    open: boolean;
    onConfirm: () => void;
    onClose: () => void;
}

export const UploadDocumentConfirmationDialog: React.FC<IUploadDocumentConfirmationDialogProps> = ({
    open,
    onConfirm,
    onClose,
}) => {
    const classes = useClasses();

    const onConfirmUpload = () => {
        onConfirm();
    };

    return (
        <Dialog
            open={open}
            onOpenChange={(_ev: any, data: DialogOpenChangeData) => {
                if (!data.open) onClose();
            }}
        >
            <DialogSurface className={classes.root}>
                <DialogBody>
                    <DialogTitle>Acknowledge Document Upload</DialogTitle>

                    <DialogContent className={classes.dialogContent}>
                        <p>
                            Please <strong>do NOT upload PROTECTED documents</strong>.
                        </p>
                        <div className={classes.rowContent}>
                            <Info20Filled />
                            <p>Only PDF and TXT files are supported documents types for upload.</p>
                        </div>
                    </DialogContent>

                    <DialogActions className={classes.actions}>
                        <DialogTrigger action="close" disableButtonEnhancement>
                            <Button appearance="secondary">Cancel</Button>
                        </DialogTrigger>

                        <DialogTrigger action="close" disableButtonEnhancement>
                            <Button appearance="primary" onClick={onConfirmUpload}>
                                Acknowledge
                            </Button>
                        </DialogTrigger>
                    </DialogActions>
                </DialogBody>
            </DialogSurface>
        </Dialog>
    );
};
