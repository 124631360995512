import { makeStyles, tokens, Divider, shorthands } from '@fluentui/react-components';
import React, { useEffect, useState } from 'react';
import DropdownTreeSelect, { TreeNode } from 'react-dropdown-tree-select';
import { useTree } from '../../libs/hooks/useTree';
import { FileDropZone } from '../shared/FileDropZone';
import { useFile } from '../../libs/hooks';
import { useCollection } from '../../libs/hooks/useCollection';
import { useAppDispatch, useAppSelector } from '../../redux/app/hooks';
import { editCollectionName as updateCollectionName } from '../../redux/features/collections/collectionsSlice';
import { DocumentStatus } from '../../libs/generated';
import { isEqualToEnumMember } from '../utils/GenericUtils';

const useClasses = makeStyles({
    root: {
        minWidth: '300px',
        borderLeft: '1px solid rgba(0, 0, 0, 0.1)',
        maxHeight: '',
    },
    tabPanelCustom: {
        maxHeight: '100px',
    },
    drawerLabel: {
        fontWeight: 600,
        margin: 0,
    },
    drawerSubLabel: {
        fontWeight: 400,
        margin: 0,
        marginTop: '-10px',
        color: 'rgba(0, 0, 0, 0.6)',
    },
    drawerHeaderText: {
        display: 'flex',
        alignItems: 'center',
        gap: tokens.spacingHorizontalS,
    },
    drawerHeader: {
        paddingTop: tokens.spacingVerticalM,
    },
    divider: {
        ...shorthands.margin(tokens.spacingVerticalM, tokens.spacingHorizontalNone),
    },
    emptySpace: {
        paddingTop: tokens.spacingHorizontalXL,
        width: '100%',
    },
    control: {
        width: '97%',
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        gap: tokens.spacingHorizontalMNudge,
    },
    errorText: {
        color: 'red',
        fontSize: '14px',
        fontWeight: 600,
        margin: 0,
    },
});

interface Props {
    onChangeWillTemplate: (id: string) => void;
    onChangeSupplimentaryFiles: (doucmentIds: string[], collectionIds: string[]) => void;
}

export const WillDrawer: React.FC<Props> = ({ onChangeWillTemplate, onChangeSupplimentaryFiles }) => {
    const [selectedWillDocumentNodes, setSelectedWillDocumentNodes] = useState<string[]>([]);
    const [selectedSupportingDocumentNodes, setSelectedSupportingDocumentNodes] = useState<string[]>([]);
    const [collectionId, setCollectionId] = useState<string | undefined>(undefined);

    const classes = useClasses();
    const fileHandler = useFile();
    const collections = useAppSelector((state) => state.collections);
    const dispatch = useAppDispatch();

    const { refreshCollection, createCollection, editCollection } = useCollection();
    const { allDocuments: allDocumentsForWillTemplate, settings: templateSettings } = useTree({
        selectedDocumentNodes: selectedWillDocumentNodes,
    });
    const { allCollections: allCollectionsForSupportingDocuments, settings: supplimentarySettings } = useTree({
        selectedDocumentNodes: selectedSupportingDocumentNodes,
    });

    const changeWillTemplate = (_currentNode: TreeNode, selectedNodes: TreeNode[]) => {
        setSelectedWillDocumentNodes(selectedNodes.map((node: TreeNode) => node.value));
    };

    const changeSupportingDocuments = (_currentNode: TreeNode, selectedNodes: TreeNode[]) => {
        setSelectedSupportingDocumentNodes(selectedNodes.map((node: TreeNode) => node.value));
    };

    useEffect(() => {
        onChangeWillTemplate(templateSettings.documentIds[0]);
        onChangeSupplimentaryFiles(supplimentarySettings.documentIds, supplimentarySettings.collectionIds);
    }, [
        selectedWillDocumentNodes,
        onChangeWillTemplate,
        templateSettings,
        onChangeSupplimentaryFiles,
        supplimentarySettings,
    ]);

    useEffect(() => {
        const willCollection = Object.values(collections.collections).find(
            (collection) => collection.name === 'Will Generation',
        );
        if (willCollection == null) {
            void createCollection(true).then((newCollection) => {
                if (newCollection != null) {
                    void editCollection(newCollection.id, 'Will Generation');
                    dispatch(updateCollectionName({ id: newCollection.id, newName: 'Will Generation' }));
                    setCollectionId(newCollection.id);
                }
            });
        } else {
            setCollectionId(willCollection.id);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (
            collectionId &&
            Object.values(collections.collections[collectionId].documents).some(
                (document) => !isEqualToEnumMember(document.status, DocumentStatus.COMPLETED),
            )
        ) {
            const interval = setInterval(() => {
                void refreshCollection(collectionId);
            }, 5000);

            return () => {
                clearInterval(interval);
            };
        }

        return () => {};
    }, [collectionId, collections.collections, refreshCollection]);

    return (
        <>
            <div className={classes.tabPanelCustom} role="tabpanel" aria-labelledby="WillDrawer">
                <div className={classes.control}>
                    <p className={classes.drawerLabel}>Will Template</p>
                    <p className={classes.drawerSubLabel}>Exactly one template is required</p>
                    <DropdownTreeSelect
                        mode="simpleSelect"
                        texts={{ placeholder: 'Select will template...' }}
                        data={allDocumentsForWillTemplate}
                        onChange={changeWillTemplate}
                        keepOpenOnSelect={true}
                        className="document-selector will-template"
                        disabled={allDocumentsForWillTemplate.length === 0}
                    />
                </div>

                <Divider className={classes.divider} />
                <div className={classes.control}>
                    <p className={classes.drawerLabel}>Supporting Documents</p>
                    <p className={classes.drawerSubLabel}>At least one document is required</p>
                    <DropdownTreeSelect
                        mode="multiSelect"
                        texts={{ placeholder: 'Select supporting documents...' }}
                        data={allCollectionsForSupportingDocuments}
                        onChange={changeSupportingDocuments}
                        keepOpenOnSelect={true}
                        className="document-selector will-template"
                        disabled={allDocumentsForWillTemplate.length === 0}
                    />
                </div>

                <Divider className={classes.divider} />
                <div className={classes.control}>
                    <p className={classes.drawerLabel}>Upload Additional Documents</p>
                    <FileDropZone
                        onFileUpload={(file) => {
                            if (!collectionId) {
                                return;
                            }

                            const fileList = new FileList();
                            fileList[0] = file;

                            void fileHandler.handleImport(collectionId, null, undefined, fileList).then(() => {
                                void refreshCollection(collectionId);
                            });
                        }}
                        image={undefined}
                        acceptedFileTypes={['.pdf']}
                        maxFileSize={10485760}
                        backgroundColor="transparent"
                    />

                    {collectionId &&
                        Object.values(collections.collections[collectionId].documents)
                            .filter((document) => !isEqualToEnumMember(document.status, DocumentStatus.COMPLETED))
                            .map((document) => <div key={document.id}>Loading {document.name}...</div>)}
                </div>

                {allDocumentsForWillTemplate.length === 0 && (
                    <>
                        <Divider className={classes.divider} />
                        <div className={classes.control}>
                            <p className={classes.errorText}>
                                No documents available. To be able to use the Will Generator, at least one document must
                                be uploaded. Use the file-dropper above or head to the {'"Collections"'} tab to upload a
                                document.
                            </p>
                        </div>
                    </>
                )}
            </div>
        </>
    );
};
