import {
    BrandVariants,
    GriffelStyle,
    Theme,
    createDarkTheme,
    createLightTheme,
    makeStyles,
    shorthands,
    tokens,
} from '@fluentui/react-components';

interface Extras {
    colorMeBackground: string;
    colorMeBackground2: string;
}

export const lightTheme = (style: Record<string, string>): Theme & Extras => {
    const brand = Object.entries(style).reduce<Record<number, string>>((acc, [key, value]) => {
        const newKey = Number(key);
        if (!isNaN(newKey)) {
            acc[newKey] = value;
        }

        return acc;
    }, {});

    return {
        ...createLightTheme(brand as BrandVariants),
        colorMeBackground: style.chatBubblePrimaryColor,
        colorMeBackground2: style.chatBubbleSecondaryColor,
    };
};

export const darkTheme = (style: Record<string, string>): Theme & Extras => {
    const brand = Object.entries(style).reduce<Record<number, string>>((acc, [key, value]) => {
        const newKey = Number(key);
        if (!isNaN(newKey)) {
            acc[newKey] = value;
        }

        return acc;
    }, {});

    return {
        ...createDarkTheme(brand as BrandVariants),
        colorMeBackground: style.chatBubblePrimaryColor,
        colorMeBackground2: style.chatBubbleSecondaryColor,
    };
};

export const customTokens: Extras = {
    colorMeBackground: 'var(--colorMeBackground)',
    colorMeBackground2: 'var(--colorMeBackground2)',
};

export const BreakpointThresholds = {
    small: 745,
    medium: 1025,
    large: 1441,
};

export const Breakpoints = {
    small: (style: GriffelStyle): Record<string, GriffelStyle> => {
        return { '@media (max-width: 744px)': style };
    },
    medium: (style: GriffelStyle): Record<string, GriffelStyle> => {
        return { '@media (max-width: 1024px)': style };
    },
    large: (style: GriffelStyle): Record<string, GriffelStyle> => {
        return { '@media (max-width: 1440px)': style };
    },
};

export const ScrollBarStyles: GriffelStyle = {
    overflowY: 'auto',
    '&::-webkit-scrollbar': {
        width: '0.4rem',
    },
    '&::-webkit-scrollbar-thumb': {
        borderRadius: '12px',
        visibility: 'hidden',
    },
    '&:hover': {
        '&::-webkit-scrollbar-thumb': {
            backgroundColor: tokens.colorScrollbarOverlay,
            visibility: 'visible',
        },
        '&::-webkit-scrollbar-track': {
            backgroundColor: tokens.colorNeutralBackground1,
            WebkitBoxShadow: 'inset 0 0 5px rgba(0, 0, 0, 0.1)',
            visibility: 'visible',
        },
    },
};

export const SharedStyles: Record<string, GriffelStyle> = {
    scroll: {
        height: '100%',
        ...ScrollBarStyles,
    },
    overflowEllipsis: {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
    },
};

export const useSharedClasses = makeStyles({
    informativeView: {
        display: 'flex',
        flexDirection: 'column',
        ...shorthands.padding('80px'),
        alignItems: 'center',
        ...shorthands.gap(tokens.spacingVerticalXL),
        marginTop: tokens.spacingVerticalXXXL,
        textAlign: 'center',
    },
});

export const useDialogClasses = makeStyles({
    surface: {
        paddingRight: tokens.spacingVerticalXS,
    },
    content: {
        display: 'flex',
        flexDirection: 'column',
        overflow: 'hidden',
        width: '100%',
    },
    paragraphs: {
        marginTop: tokens.spacingHorizontalS,
    },
    innerContent: {
        height: '100%',
        ...SharedStyles.scroll,
        paddingRight: tokens.spacingVerticalL,
    },
    text: {
        whiteSpace: 'pre-wrap',
        textOverflow: 'wrap',
    },
    footer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        minWidth: '175px',
    },
});
