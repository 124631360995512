/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CreateTemplateRequest } from '../models/CreateTemplateRequest';
import type { CreateTemplateResponse } from '../models/CreateTemplateResponse';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class TemplateGeneratorApiService {
    /**
     * @param requestBody
     * @returns CreateTemplateResponse Success
     * @throws ApiError
     */
    public static createTemplateEndpoint(
        requestBody: CreateTemplateRequest,
    ): CancelablePromise<CreateTemplateResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/templateGenerator',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
            },
        });
    }
}
