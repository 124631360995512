import { Button } from '@fluentui/react-button';
import { makeStyles, mergeClasses, shorthands, tokens } from '@fluentui/react-components';
import { Input, InputOnChangeData } from '@fluentui/react-input';
import { useState } from 'react';
import { AlertType } from '../../../libs/models/AlertType';
import { useAppDispatch } from '../../../redux/app/hooks';
import { addAlert } from '../../../redux/features/app/appSlice';
import { Breakpoints } from '../../../styles';
import { Checkmark20, Dismiss20 } from '../../shared/BundledIcons';
import { getErrorDetails } from '../../utils/TextUtils';
import { useCollection } from '../../../libs/hooks/useCollection';
import { editCollectionName } from '../../../redux/features/collections/collectionsSlice';

const useClasses = makeStyles({
    root: {
        width: '100%',
        ...Breakpoints.small({
            display: 'none',
        }),
    },
    buttons: {
        display: 'flex',
        alignSelf: 'end',
    },
    textButtons: {
        ...shorthands.gap(tokens.spacingVerticalS),
    },
    inputEdit: {
        width: '158px',
    },
});

interface IEditCollectionNameProps {
    name: string;
    collectionId: string;
    exitEdits: () => void;
    textButtons?: boolean;
}

export const EditCollectionName: React.FC<IEditCollectionNameProps> = ({
    name,
    collectionId,
    exitEdits,
    textButtons,
}) => {
    const classes = useClasses();
    const dispatch = useAppDispatch();
    const collection = useCollection();

    const [newName = '', setName] = useState<string | undefined>(name);

    const onSaveTitleChange = async () => {
        if (name !== newName) {
            await collection.editCollection(collectionId, newName).then(() => {
                dispatch(editCollectionName({ id: collectionId, newName }));
            });
        }

        exitEdits();
    };

    const onClose = () => {
        setName(name);
        exitEdits();
    };

    const onTitleChange = (_ev: React.ChangeEvent<HTMLInputElement>, data: InputOnChangeData) => {
        setName(data.value);
    };

    const handleSave = () => {
        onSaveTitleChange().catch((e: any) => {
            const errorMessage = `Unable to retrieve chat to change title. Details: ${getErrorDetails(e)}`;
            dispatch(addAlert({ message: errorMessage, type: AlertType.Error }));
        });
    };

    const handleKeyDown: React.KeyboardEventHandler<HTMLElement> = (event) => {
        if (event.key === 'Enter') {
            handleSave();
        }
    };

    return (
        <div
            className={classes.root}
            style={{
                display: 'flex',
                flexDirection: textButtons ? 'column' : 'row',
                gap: textButtons ? tokens.spacingVerticalS : tokens.spacingVerticalNone,
            }}
            title={'Edit collection name'}
            aria-label={`Edit collection name for "${name}"`}
        >
            <Input
                value={newName}
                onChange={onTitleChange}
                id={`input-${collectionId}`}
                onKeyDown={handleKeyDown}
                autoFocus
                className={classes.inputEdit}
            />

            {textButtons && (
                <div className={mergeClasses(classes.buttons, classes.textButtons)}>
                    <Button appearance="secondary" onClick={onClose}>
                        Cancel
                    </Button>

                    <Button type="submit" appearance="primary" onClick={handleSave}>
                        Save
                    </Button>
                </div>
            )}

            {!textButtons && (
                <div className={classes.buttons}>
                    <Button icon={<Dismiss20 />} onClick={onClose} appearance="transparent" />

                    <Button icon={<Checkmark20 />} onClick={handleSave} appearance="transparent" />
                </div>
            )}
        </div>
    );
};
