import { Button, makeStyles, mergeClasses, tokens, Subtitle2Stronger } from '@fluentui/react-components';
import { Dismiss24Regular } from '@fluentui/react-icons';
import React from 'react';

const useStyles = makeStyles({
    root: {
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: tokens.colorNeutralBackground4,
        boxShadow: '0 2px 8px rgba(0, 0, 0, 0.2)',
        width: '320px',
        transition: 'transform 0.3s ease, opacity 0.3s ease',
        position: 'fixed',
        bottom: 0,
        right: 0,
        height: '96vh',
        zIndex: 1000,
        overflow: 'hidden',
    },
    hidden: {
        transform: 'translateX(100%)',
        opacity: 0,
    },
    visible: {
        transform: 'translateX(0)',
        opacity: 1,
    },
    header: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        alignContent: 'center',
        padding: tokens.spacingHorizontalXL,
        paddingTop: tokens.spacingVerticalXXL,
        paddingBottom: tokens.spacingVerticalS,
        backgroundColor: tokens.colorNeutralBackground5,
        boxShadow: `0 1px 4px rgba(0, 0, 0, 0.1)`,
        zIndex: 2,
    },
    headerText: {
        display: 'flex',
        alignItems: 'center',
        gap: tokens.spacingHorizontalS,
    },
    content: {
        flexGrow: 1,
        overflowY: 'auto',
        padding: tokens.spacingHorizontalXL,
        paddingTop: tokens.spacingVerticalL,
        zIndex: 1,
    },
    closeButton: {
        backgroundColor: tokens.colorNeutralBackground5,
    },
});

export interface DrawerProps {
    header: {
        icon: React.ReactNode;
        text: string;
    };
    body: React.ReactNode;
    open: boolean;
    onClose: () => void;
}

export const Drawer: React.FC<DrawerProps> = ({ header, body, open, onClose }) => {
    const classes = useStyles();

    return (
        <div className={mergeClasses(classes.root, open ? classes.visible : classes.hidden)}>
            <div className={classes.header}>
                <div className={classes.headerText}>
                    {header.icon}
                    <Subtitle2Stronger>{header.text}</Subtitle2Stronger>
                </div>
                <Button
                    icon={<Dismiss24Regular />}
                    appearance="subtle"
                    aria-label="Close"
                    onClick={onClose}
                    className={classes.closeButton}
                />
            </div>

            <div className={classes.content}>{body}</div>
        </div>
    );
};
