import { FC, useMemo, useState } from 'react';
import { Tab, TabList } from '@fluentui/react-tabs';
import { Menu, MenuTrigger, MenuPopover, MenuList, MenuItem } from '@fluentui/react-components';
import { useAppSelector } from '../../redux/app/hooks';
import { Constants } from '../../Constants';
import { makeStyles, shorthands, tokens } from '@fluentui/react-components';

interface INavigationTabsProps {
    setChatState: () => void;
    setCollectionState: () => void;
    setPersonaState: () => void;
    setRfxChatState: () => void;
    setAdminSettingsState: () => void;
    setWillState: () => void;
}

export const useClasses = makeStyles({
    rolesLabel: {
        ...shorthands.padding(tokens.spacingVerticalM, tokens.spacingHorizontalMNudge),
    },
    popoverMenu: {
        marginTop: '10px',
    },
    popover: {
        marginTop: '16px!important',
    },
    tabText: {
        color: '#fff',
        padding: '0px',
    },
    tabListCustom: {
        gap: tokens.spacingHorizontalL,
    },
    menuTab: {
        height: '35px',
    },
});

export const NavigationTabs: FC<INavigationTabsProps> = ({
    setChatState,
    setCollectionState,
    setPersonaState,
    setRfxChatState,
    setAdminSettingsState,
    setWillState,
}) => {
    const [tab, setTab] = useState('chats');

    const { serviceInfo } = useAppSelector((state) => state.app);

    const classes = useClasses();

    const isAdmin = useMemo(() => {
        return !!serviceInfo.roles.find((role) => role === Constants.roles.admin);
    }, [serviceInfo.roles]);

    const isAnyRoles = useMemo(() => {
        return serviceInfo.roles.length > 0;
    }, [serviceInfo.roles]);

    const skills = useMemo(() => {
        const selectRfxChat = () => {
            setRfxChatState();
            setTab('skills');
        };

        const selectWill = () => {
            setWillState();
            setTab('skills');
        };

        return (
            <MenuPopover>
                <MenuList>
                    <MenuItem onClick={selectRfxChat} data-testid="rfxButton">
                        RFX
                    </MenuItem>
                    <MenuItem onClick={selectWill} data-testid="willButton">
                        Will Generator
                    </MenuItem>
                </MenuList>
            </MenuPopover>
        );
    }, [setRfxChatState, setWillState]);

    return (
        <TabList
            selectedValue={tab}
            size="small"
            className={classes.tabListCustom}
            onTabSelect={(_e, tab) => {
                const val = tab.value as string;

                if (val === 'skills') {
                    return;
                }

                setTab(val);
            }}
        >
            <Tab
                value="chats"
                data-testid="chatsButton"
                className={classes.menuTab}
                onClick={() => {
                    setChatState();
                }}
            >
                <p className={classes.tabText}>Chats</p>
            </Tab>
            {isAnyRoles && (
                <Tab
                    value="collections"
                    data-testid="collectionsButton"
                    className={classes.menuTab}
                    onClick={() => {
                        setCollectionState();
                    }}
                >
                    <p className={classes.tabText}>Collections</p>
                </Tab>
            )}
            {isAdmin && (
                <Tab
                    value="personas"
                    data-testid="personasButton"
                    className={classes.menuTab}
                    onClick={() => {
                        setPersonaState();
                    }}
                >
                    <p className={classes.tabText}>Personas</p>
                </Tab>
            )}
            <Menu>
                <MenuTrigger>
                    <Tab value="skills" data-testid="skillsButton" className={classes.menuTab}>
                        <p className={classes.tabText}>Skills</p>
                    </Tab>
                </MenuTrigger>
                {skills}
            </Menu>
            {isAdmin && (
                <Tab
                    value="adminSettings"
                    data-testid="adminSettingsButton"
                    className={classes.menuTab}
                    onClick={() => {
                        setAdminSettingsState();
                    }}
                >
                    <p className={classes.tabText}>Admin</p>
                </Tab>
            )}
        </TabList>
    );
};
