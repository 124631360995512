import { useState, useCallback } from 'react';
import { parseAnswerToHtml } from '../../components/utils/ChatUtils';
import { ChatMessageHeader, CitationSource } from '../generated';
import { IHtmlParsedAnswer } from '../../redux/features/chats/ChatState';

type State = { type: 'empty' } | { type: 'ready'; message: ChatMessageHeader; parsedAnswer: IHtmlParsedAnswer };

const emptyParsedAnswer: IHtmlParsedAnswer = {
    answerHtml: '',
    answerText: '',
    citations: [],
    followupQuestions: [],
};

function useParseAnswer() {
    const [state, setState] = useState<State>({ type: 'empty' });
    const [activeCitation, setActiveCitation] = useState<CitationSource | null>(null);

    const onShowCitation = useCallback(
        (citationSourceName: string) => {
            if (state.type !== 'ready') return;

            let citation = state.message.citations.find((x) => x.sourceFile === citationSourceName);

            if (!citation) {
                citation = state.message.citations.find((x) => x.sourceFile.includes(citationSourceName));
            }

            if (citation) {
                setActiveCitation(citation);
            }
        },
        [state],
    );

    const setAnswer = useCallback(
        (message: ChatMessageHeader) => {
            const parsedAnswer = parseAnswerToHtml(message.agentResponse, onShowCitation);

            setState({ type: 'ready', message, parsedAnswer });
        },
        [onShowCitation],
    );

    const clearActiveCitation = useCallback(() => {
        setActiveCitation(null);
    }, []);

    return {
        parsedAnswer: state.type === 'ready' ? state.parsedAnswer : emptyParsedAnswer,
        citations: state.type === 'ready' ? state.message.citations : [],
        activeCitation,
        onShowCitation,
        clearActiveCitation,
        setAnswer,
    };
}

export default useParseAnswer;
