/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AskChatRequest } from '../models/AskChatRequest';
import type { AskChatResponse } from '../models/AskChatResponse';
import type { CreateChatSessionRequest } from '../models/CreateChatSessionRequest';
import type { CreateChatSessionResponse } from '../models/CreateChatSessionResponse';
import type { RetrieveChatMessagesResponse } from '../models/RetrieveChatMessagesResponse';
import type { RetrieveChatSessionResponse } from '../models/RetrieveChatSessionResponse';
import type { RetrieveChatSessionsResponse } from '../models/RetrieveChatSessionsResponse';
import type { UpdateChatSessionRequest } from '../models/UpdateChatSessionRequest';
import type { UpdateChatSessionResponse } from '../models/UpdateChatSessionResponse';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class ChatApiService {
    /**
     * @param id
     * @param requestBody
     * @returns AskChatResponse Success
     * @throws ApiError
     */
    public static askChatEndpoint(
        id: string,
        requestBody: AskChatRequest,
    ): CancelablePromise<AskChatResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/chat/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
            },
        });
    }
    /**
     * @param id
     * @returns void
     * @throws ApiError
     */
    public static deleteChatSessionEndpoint(
        id: string,
    ): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/chat/{id}',
            path: {
                'id': id,
            },
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
            },
        });
    }
    /**
     * @param id
     * @returns RetrieveChatSessionResponse Success
     * @throws ApiError
     */
    public static retrieveChatSessionEndpoint(
        id: string,
    ): CancelablePromise<RetrieveChatSessionResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/chat/{id}',
            path: {
                'id': id,
            },
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
            },
        });
    }
    /**
     * @param id
     * @param requestBody
     * @returns UpdateChatSessionResponse Success
     * @throws ApiError
     */
    public static updateChatSessionEndpoint(
        id: string,
        requestBody: UpdateChatSessionRequest,
    ): CancelablePromise<UpdateChatSessionResponse> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/api/chat/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
            },
        });
    }
    /**
     * @param requestBody
     * @returns CreateChatSessionResponse Success
     * @throws ApiError
     */
    public static createChatSessionEndpoint(
        requestBody: CreateChatSessionRequest,
    ): CancelablePromise<CreateChatSessionResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/chat',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
            },
        });
    }
    /**
     * @returns RetrieveChatSessionsResponse Success
     * @throws ApiError
     */
    public static retrieveChatSessionsEndpoint(): CancelablePromise<RetrieveChatSessionsResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/chat',
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
            },
        });
    }
    /**
     * @param id
     * @returns RetrieveChatMessagesResponse Success
     * @throws ApiError
     */
    public static retrieveChatMessagesEndpoint(
        id: string,
    ): CancelablePromise<RetrieveChatMessagesResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/chat/{id}/Messages',
            path: {
                'id': id,
            },
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
            },
        });
    }
}
