import { Button, makeStyles, Label, Tooltip, tokens } from '@fluentui/react-components';
import { Info24Regular } from '@fluentui/react-icons';
import React, { useEffect, useMemo, useReducer, useState } from 'react';
import { RootState } from '../../../redux/app/store';
import { useAppDispatch, useAppSelector } from '../../../redux/app/hooks';
import DropdownTreeSelect, { TreeData, TreeNode } from 'react-dropdown-tree-select';
import { getFormattedRoleName } from '../../utils/CollectionUtils';
import { ICollectionState } from '../../../redux/features/collections/CollectionState';
import { Collections } from '../../../redux/features/collections/CollectionsState';
import '../../../assets/styles/tree-select.css';
import { useChat } from '../../../libs/hooks';
import { editChatSettings } from '../../../redux/features/chats/chatsSlice';
import { AlertType } from '../../../libs/models/AlertType';
import { addAlert } from '../../../redux/features/app/appSlice';
import { getErrorDetails } from '../../utils/TextUtils';
import { ChatSettings } from '../../../libs/generated';
import { getDefaultPersona } from '../../utils/PesonaUtils';

const useClasses = makeStyles({
    infoButton: {
        alignItems: 'flex-end',
    },
    header: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    },
    content: {
        marginBottom: '1rem',
    },
    danger: {
        color: 'red',
        fontStyle: 'italic',
        fontSize: tokens.fontSizeBase100,
    },
});

interface IOtherCollectionGroup {
    role: string;
    name: string;
    collections: ICollectionState[];
}

export const DocumentsDrawer: React.FC = () => {
    const dispatch = useAppDispatch();
    const chat = useChat();
    const classes = useClasses();

    const { chats, selectedId } = useAppSelector((state: RootState) => state.chat);
    const { collections } = useAppSelector((state: RootState) => state.collections);
    const { personas } = useAppSelector((state: RootState) => state.personas);

    const [selectedDocumentNodes, setSelectedDocumentNodes] = useState<string[]>([]);
    const [update, forceUpdate] = useReducer((x: number) => x + 1, 0);

    const keys = useMemo(() => {
        return Object.keys(collections);
    }, [collections]);

    useEffect(() => {
        const selectedChat = chats[selectedId];

        const collectionIds = selectedChat.settings.collectionIds.map((collectionId) => `collection..${collectionId}`);

        const documentIds = selectedChat.settings.documentIds.map((documentId) => {
            let collectionId = '';
            keys.forEach((key) => {
                const matchingDocument = collections[key].documents.find((x) => x.id === documentId);
                if (matchingDocument) {
                    collectionId = matchingDocument.collectionId;
                }
            });
            return `document..${documentId}..${collectionId}`;
        });

        setSelectedDocumentNodes([...collectionIds, ...documentIds]);

        forceUpdate();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedId]);

    const publicCollections: TreeNode = useMemo(() => {
        const allPublicCollections = keys
            .filter((key) => {
                const currentCollection = collections[key];
                return !currentCollection.isPrivate && !currentCollection.role;
            })
            .map((key) => mapToCollectionNode(collections, selectedDocumentNodes, key))
            .sort((a, b) => {
                return a.label.localeCompare(b.label);
            });

        return {
            label: 'Public Collections',
            value: 'public-collections',
            expanded: true,
            children: allPublicCollections,
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [collections, keys, update]);

    const otherCollections: TreeNode[] = useMemo(() => {
        const sortedCollections: TreeNode[] = [];
        const collectionGroups: IOtherCollectionGroup[] = [];

        keys.forEach((key) => {
            const currentCollection = collections[key];
            if (currentCollection.isPrivate || !currentCollection.role) {
                return;
            }

            const existingCollectionGroup = collectionGroups.find((x) => x.role === currentCollection.role);
            if (!existingCollectionGroup) {
                collectionGroups.push({
                    role: currentCollection.role ?? '',
                    name: getFormattedRoleName(currentCollection.role) ?? '',
                    collections: [currentCollection],
                });
                return;
            }

            existingCollectionGroup.collections.push(currentCollection);
        });

        collectionGroups.forEach((collectionGroup) => {
            const allOtherCollections = collectionGroup.collections
                .map((collection) => mapToCollectionNode(collections, selectedDocumentNodes, collection.id))
                .sort((a, b) => {
                    return a.label.localeCompare(b.label);
                });

            sortedCollections.push({
                label: `${collectionGroup.name} Collections`,
                value: `${collectionGroup.role}-collections`,
                expanded: true,
                children: allOtherCollections,
            });
        });

        return sortedCollections;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [collections, keys, update]);

    const privateCollections: TreeNode = useMemo(() => {
        const allPrivateCollections = keys
            .filter((key) => {
                const currentCollection = collections[key];
                return currentCollection.isPrivate;
            })
            .map((key) => mapToCollectionNode(collections, selectedDocumentNodes, key))
            .sort((a, b) => {
                return a.label.localeCompare(b.label);
            });

        return {
            label: 'Private Collections',
            value: 'private-collections',
            expanded: true,
            children: allPrivateCollections,
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [collections, keys, update]);

    const data: TreeData[] = useMemo(() => {
        return [privateCollections, ...otherCollections, publicCollections];
    }, [privateCollections, otherCollections, publicCollections]);

    const onChange = (_currentNode: TreeNode, selectedNodes: TreeNode[]) => {
        const selectedDocumentNodesTemp = [...selectedNodes.map((x) => x.value)];
        setSelectedDocumentNodes(selectedDocumentNodesTemp);
        handleSave(selectedDocumentNodesTemp);
    };

    const onSave = async (selectedDocumentNodesInput: string[]) => {
        const chatId = selectedId;

        const collectionIds = selectedDocumentNodesInput
            .filter((x) => x.startsWith('collection..'))
            .map((x) => x.split('..')[1]);
        const selectedDocuments = selectedDocumentNodesInput
            .filter((x) => x.startsWith('document..'))
            .map((x) => {
                const splits = x.split('..');
                return {
                    documentId: splits[1],
                    collectonId: splits[2],
                };
            });

        const documentIds: string[] = [];

        selectedDocuments.forEach((selectedDocument) => {
            if (collectionIds.includes(selectedDocument.collectonId)) {
                return;
            }

            documentIds.push(selectedDocument.documentId);
        });

        const isAllPrivateCollectionsSelected = selectedDocumentNodesInput.find((x) => x === 'private-collections');
        if (isAllPrivateCollectionsSelected) {
            keys.forEach((key) => {
                if (collections[key].isPrivate) {
                    collectionIds.push(key);
                }
            });
        }

        // TODO: Andrew - Handle other types of collections
        otherCollections.forEach((otherCollection) => {
            const isAllOtherCollectionsSelected = selectedDocumentNodesInput.find((x) => x === otherCollection.value);
            if (isAllOtherCollectionsSelected) {
                keys.forEach((key) => {
                    if (`${collections[key].role}-collections` === otherCollection.value) {
                        collectionIds.push(key);
                    }
                });
            }
        });

        const isAllPublicCollectionsSelected = selectedDocumentNodesInput.find((x) => x === 'public-collections');
        if (isAllPublicCollectionsSelected) {
            keys.forEach((key) => {
                if (!collections[key].isPrivate) {
                    collectionIds.push(key);
                }
            });
        }

        // If a selected persona doesn't exist, set one. Existing chats may have no personaId
        let personaId = chats[selectedId].settings.personaId;
        if (!personaId) {
            personaId = getDefaultPersona(personas).id;
        }

        const settings: ChatSettings = {
            collectionIds: collectionIds,
            documentIds: documentIds,
            personaId: personaId,
        };

        // setIsSaving(true);

        await chat.editChat(chatId, undefined, settings).then(() => {
            dispatch(editChatSettings({ id: chatId, settings }));
        });
    };

    const handleSave = (selectedDocumentNodesInput: string[]) => {
        onSave(selectedDocumentNodesInput)
            // TODO: To be replaced by new alerts see issue #109
            // .then(() => {
            //     dispatch(addAlert({ message: 'Chat settings updated successfully!', type: AlertType.Success }));
            // })
            .catch((e: any) => {
                const errorMessage = `Unable to save chat settings. Details: ${getErrorDetails(e)}`;
                dispatch(addAlert({ message: errorMessage, type: AlertType.Error }));
            });
    };

    const showDocumentWarning = useMemo(() => {
        return personas[chats[selectedId].settings.personaId].requiresDocuments && selectedDocumentNodes.length === 0;
    }, [chats, personas, selectedDocumentNodes.length, selectedId]);

    const treeSelect = useMemo(() => {
        return (
            <div>
                <div className="collectionsHeader">
                    <Label weight="regular">Collections and Documents</Label>
                    <Tooltip
                        content="Select the collections and documents that you want for this chat. Only the selected documents and collections will be used when asking questions."
                        relationship="label"
                    >
                        <Button
                            className={classes.infoButton}
                            icon={<Info24Regular />}
                            appearance="transparent"
                            aria-label="Info: Select the collections and documents that you want for this chat. Only the selected documents and collections will be used when asking questions."
                        />
                    </Tooltip>
                </div>

                <DropdownTreeSelect
                    texts={{ placeholder: 'Search...' }}
                    data={data}
                    onChange={onChange}
                    keepOpenOnSelect={true}
                    className="document-selector"
                />
            </div>
        );
        // eslint-disable-next-line
    }, [classes.infoButton, data]);

    return (
        <div className={classes.content}>
            <div className={classes.header}>
                <Label weight="semibold">Documents</Label>
            </div>
            {treeSelect}
            {showDocumentWarning && (
                <Label className={classes.danger}>
                    * No documents have been selected, you must select at least one document to chat against.
                </Label>
            )}
        </div>
    );
};

function mapToCollectionNode(collections: Collections, selectedDocumentNodes: string[], key: string) {
    const currentCollection = collections[key];

    const isCollectionChecked = selectedDocumentNodes.find((x) => x.includes(`collection..${key}`)) ? true : false;

    const children = currentCollection.documents
        .map((document) => {
            return {
                value: `document..${document.id}..${document.collectionId}`,
                label: document.name,
                checked:
                    isCollectionChecked || selectedDocumentNodes.find((x) => x.includes(document.id)) ? true : false,
            };
        })
        .sort((a, b) => {
            return a.label.localeCompare(b.label);
        });

    return {
        label: `${currentCollection.name} (${currentCollection.documents.length})`,
        value: `collection..${key}`,
        checked: isCollectionChecked,
        expanded: isCollectionChecked || children.find((x) => x.checked),
        children: children,
    };
}
