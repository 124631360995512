import { Button, makeStyles, tokens } from '@fluentui/react-components';
import { Settings20Regular } from '@fluentui/react-icons';
import React, { useMemo, useState } from 'react';
import { RfxChatRoom } from './RfxChatRoom';
import { useAppSelector } from '../../redux/app/hooks';
import { RootState } from '../../redux/app/store';

const useClasses = makeStyles({
    root: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        backgroundColor: tokens.colorNeutralBackground4,
        boxShadow: 'rgb(0 0 0 / 25%) 0 0.2rem 0.4rem -0.075rem',
    },
    buttonGroup: {
        padding: '0.5rem',
        position: 'absolute',
        right: '0',
    },
});

export const RfxChatWindow: React.FC = () => {
    const [isDrawerOpen, setIsDrawerOpen] = useState<boolean>(false);

    const { chats: conversations, selectedId } = useAppSelector((state: RootState) => state.skills.rfx);

    const classes = useClasses();

    const isNoDocumentsSelected = useMemo(() => {
        const currentConversationSettings = conversations[selectedId].settings;
        return !currentConversationSettings.collectionIds.length && !currentConversationSettings.documentIds.length;
    }, [conversations, selectedId]);

    return (
        <div className={classes.root}>
            <div className={classes.buttonGroup}>
                {!isDrawerOpen && (
                    <Button
                        appearance="transparent"
                        icon={<Settings20Regular />}
                        onClick={() => {
                            setIsDrawerOpen(true);
                        }}
                    >
                        {`Settings ${isNoDocumentsSelected ? '(No documents selected)' : ''}`}
                    </Button>
                )}
            </div>

            <RfxChatRoom isDrawerOpen={isDrawerOpen} setIsDrawerOpen={setIsDrawerOpen} />
        </div>
    );
};
