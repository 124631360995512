import { PersonaHeader } from '../../libs/generated';
import { Personas } from '../../redux/features/personas/PersonasState';

export function getDefaultPersona(personas: Personas): PersonaHeader {
    const defaultPersonas = Object.keys(personas)
        .filter((personaId) => personas[personaId].isDefault)
        .map((personaId) => personas[personaId]);

    if (!defaultPersonas.length) {
        throw new Error('No default personas.');
    }

    return defaultPersonas[0];
}
