/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CreatePersonaRequest } from '../models/CreatePersonaRequest';
import type { CreatePersonaResponse } from '../models/CreatePersonaResponse';
import type { RetrievePersonaResponse } from '../models/RetrievePersonaResponse';
import type { RetrievePersonasResponse } from '../models/RetrievePersonasResponse';
import type { UpdatePersonaRequest } from '../models/UpdatePersonaRequest';
import type { UpdatePersonaResponse } from '../models/UpdatePersonaResponse';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class PersonaApiService {
    /**
     * @param requestBody
     * @returns CreatePersonaResponse Success
     * @throws ApiError
     */
    public static createPersonaEndpoint(
        requestBody: CreatePersonaRequest,
    ): CancelablePromise<CreatePersonaResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/persona',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
            },
        });
    }
    /**
     * @returns RetrievePersonasResponse Success
     * @throws ApiError
     */
    public static retrievePersonasEndpoint(): CancelablePromise<RetrievePersonasResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/persona',
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
            },
        });
    }
    /**
     * @param id
     * @returns void
     * @throws ApiError
     */
    public static deletePersonaEndpoint(
        id: string,
    ): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/persona/{id}',
            path: {
                'id': id,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
            },
        });
    }
    /**
     * @param id
     * @returns RetrievePersonaResponse Success
     * @throws ApiError
     */
    public static retrievePersonaEndpoint(
        id: string,
    ): CancelablePromise<RetrievePersonaResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/persona/{id}',
            path: {
                'id': id,
            },
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
            },
        });
    }
    /**
     * @param id
     * @param requestBody
     * @returns UpdatePersonaResponse Success
     * @throws ApiError
     */
    public static updatePersonaEndpoint(
        id: string,
        requestBody: UpdatePersonaRequest,
    ): CancelablePromise<UpdatePersonaResponse> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/api/persona/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
            },
        });
    }
    /**
     * @param id
     * @returns any Success
     * @throws ApiError
     */
    public static setPersonaAsDefaultEndpoint(
        id: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/api/persona/{id}/setdefault',
            path: {
                'id': id,
            },
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
            },
        });
    }
}
