import { Label, makeStyles, shorthands, tokens } from '@fluentui/react-components';
import { ReactNode } from 'react';

interface GenericSectionProps<T> {
    header: string;
    items: T[];
    renderItem: (item: T) => ReactNode;
}

const useStyles = makeStyles({
    header: {
        ...shorthands.margin(tokens.spacingVerticalNone, tokens.spacingHorizontalXL),
        marginBottom: '5px',
        marginTop: '15px',
    },
    text: {
        fontWeight: 400,
        fontSize: '12px',
        color: '#665',
    },
});

export const CollapsableListSection = <T,>({ header, items, renderItem }: GenericSectionProps<T>) => {
    const classes = useStyles();

    return (
        <div>
            <div className={classes.header}>
                <Label className={classes.text}>{header}</Label>
            </div>
            <div>{items.map((item) => renderItem(item))}</div>
        </div>
    );
};
