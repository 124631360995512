import { Reducer, AnyAction, combineReducers, createAction } from '@reduxjs/toolkit';
import appReducer from '../features/app/appSlice';
import chatsReducer from '../features/chats/chatsSlice';
import collectionsReducer from '../features/collections/collectionsSlice';
import usersReducer from '../features/users/usersSlice';
import personasReducer from '../features/personas/personasSlice';
import skillsReducer from '../features/skills/skillsSlice';
import themeReducer from '../features/theme/themeSlice';
import { RootState } from './store';

// Define a top-level root state reset action
export const resetApp = createAction('resetApp');

// Define a root reducer that combines all the reducers
const rootReducer: Reducer<RootState> = combineReducers({
    app: appReducer,
    chat: chatsReducer,
    collections: collectionsReducer,
    users: usersReducer,
    personas: personasReducer,
    skills: skillsReducer,
    theme: themeReducer,
});

// Define a special resetApp reducer that handles resetting the entire state
export const resetAppReducer = (state: RootState | undefined, action: AnyAction) => {
    if (action.type === resetApp.type) {
        state = {
            app: appReducer(undefined, action),
            chat: chatsReducer(undefined, action),
            collections: collectionsReducer(undefined, action),
            users: usersReducer(undefined, action),
            personas: personasReducer(undefined, action),
            skills: skillsReducer(undefined, action),
            theme: themeReducer(undefined, action),
        };
    }

    return rootReducer(state, action);
};

export default resetAppReducer;
