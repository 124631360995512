import { Stack } from '@fluentui/react';
import { Divider, makeStyles, shorthands } from '@fluentui/react-components';
import DOMPurify from 'dompurify';
import { IHtmlParsedAnswer } from '../../redux/features/chats/ChatState';

interface Props {
    parsedAnswer: IHtmlParsedAnswer;
    onShowCitation: (citationSourceName: string) => void;
}

const useClasses = makeStyles({
    citationLearnMore: {
        marginRight: '5px',
        fontWeight: 600,
        lineHeight: '24px',
    },
    citation: {
        fontWeight: 'normal',
        lineHeight: '24px',
        textAlign: 'center',
        ...shorthands.borderRadius('4px'),
        ...shorthands.padding('0px', '8px', '0px', '8px'),
        backgroundColor: 'var(--colorBrandForeground1)',
        color: '#fff', // '#288bd8',
        ...shorthands.textDecoration('none'),
        cursor: 'pointer',
    },
    missingInformation: {
        fontWeight: 'normal',
        lineHeight: '24px',
        textAlign: 'center',
        ...shorthands.borderRadius('4px'),
        ...shorthands.padding('0px', '8px', '0px', '8px'),
        backgroundColor: 'var(--colorErrorBackground)',
        color: '#fff',
        ...shorthands.textDecoration('none'),
        cursor: 'pointer',
    },
    divider: {
        minHeight: '24px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyItems: 'center',
    },
    answerText: {
        // fontSize: '16px',
        // fontWeight: 400,
        // lineHeight: '22px',
        // paddingTop: '16px',
        whiteSpace: 'pre-line',
    },
});

export const GeneratedWill: React.FC<Props> = ({ parsedAnswer, onShowCitation }) => {
    const classes = useClasses();

    const elements = parsedAnswer.citations.reduce<Record<'citations' | 'errors', JSX.Element[]>>(
        (acc, x, i) => {
            let fileName = x.split('/')[2];
            if (!fileName) {
                fileName = x.split('/')[1];
            }

            if (!fileName) {
                fileName = x;
            }

            if (fileName.includes('MISSING')) {
                acc.errors.push(
                    <a
                        key={i}
                        className={classes.missingInformation}
                        title={fileName}
                        onClick={() => {
                            onShowCitation(x);
                        }}
                    >
                        {`${++i}. ${fileName}`}
                    </a>,
                );
            } else {
                acc.citations.push(
                    <a
                        key={i}
                        className={classes.citation}
                        title={fileName}
                        onClick={() => {
                            onShowCitation(x);
                        }}
                    >
                        {`${++i}. ${fileName}`}
                    </a>,
                );
            }

            return acc;
        },
        { citations: [], errors: [] },
    );

    return (
        <>
            <div
                className={classes.answerText}
                dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(parsedAnswer.answerHtml) }}
            ></div>

            {!!elements.citations.length && (
                <Stack.Item>
                    <div className={classes.divider}>
                        <Divider />
                    </div>

                    <Stack horizontal wrap tokens={{ childrenGap: 5 }}>
                        <span className={classes.citationLearnMore}>Citations:</span>
                        {elements.citations}
                    </Stack>
                </Stack.Item>
            )}

            {!!elements.errors.length && (
                <Stack.Item>
                    <div className={classes.divider}>
                        <Divider />
                    </div>

                    <Stack horizontal wrap tokens={{ childrenGap: 5 }}>
                        <span className={classes.citationLearnMore}>Errors:</span>
                        {elements.errors}
                    </Stack>
                </Stack.Item>
            )}
        </>
    );
};
