import { AuthenticatedTemplate, UnauthenticatedTemplate, useMsal } from '@azure/msal-react';
import { FluentProvider, Image, makeStyles, shorthands, tokens } from '@fluentui/react-components';
import { initializeIcons } from '@fluentui/react/lib/Icons';
import { useEffect, useMemo, useState } from 'react';
import { UserSettingsMenu } from './components/header/UserSettingsMenu';
import { BackendProbe, ChatView, Error, Loading, Login } from './components/views';
import { CollectionsView } from './components/views/CollectionsView';
import { PersonaView } from './components/views/PersonaView';
import { useChat, usePersona } from './libs/hooks';
import { useCollection } from './libs/hooks/useCollection';
import { useAppDispatch, useAppSelector } from './redux/app/hooks';
import { RootState } from './redux/app/store';
import { setActiveUserInfo, setServiceInfo } from './redux/features/app/appSlice';
import { RfxChatView } from './components/rfx/RfxChatView';
import { useServiceInfo } from './libs/hooks/useServiceInfo';
import { AdminSettingsView } from './components/settings/AdminSettingsView';
import { useTheme } from './libs/hooks/useTheme';
import { ServiceInfoService } from './libs/services/ServiceInfoService';
import { useAuth } from './libs/hooks/useAuth';
import { TokenHelper } from './libs/auth/TokenHelper';
import { ActiveUserInfo } from './redux/features/app/AppState';
import { WillsView } from './components/will-generator/WillsView';

export const useClasses = makeStyles({
    container: {
        display: 'flex',
        flexDirection: 'column',
        height: '100vh',
        width: '100vw',
        overflow: 'hidden',
    },
    header: {
        alignItems: 'center',
        backgroundColor: tokens.colorBrandForeground2,
        color: tokens.colorNeutralForegroundOnBrand,
        display: 'flex',
        height: '48px',
        justifyContent: 'space-between',
        ...shorthands.padding(tokens.spacingVerticalNone, tokens.spacingHorizontalXL),
        boxShadow: '0 3px 5px rgba(57, 63, 72, 0.3)',
        zIndex: 9999,
    },
    headerTitle: {
        display: 'flex',
        alignItems: 'center',
    },
    persona: {
        marginRight: tokens.spacingHorizontalXXL,
    },
    cornerItems: {
        display: 'flex',
        alignItems: 'center',
        gap: tokens.spacingHorizontalXXXL,
    },
    headerLogo: {
        marginLeft: '10px',
    },
    environmentTag: {
        marginLeft: '20px',
    },
});

enum AppState {
    ProbeForBackend,
    SettingUserInfo,
    ErrorLoadingChats,
    ErrorLoadingCollections,
    ErrorLoadingPersonas,
    ErrorLoadingUserInfo,
    ErrorLoadingServiceInfo,
    LoadingServiceInfo,
    LoadingChats,
    LoadingCollections,
    LoadingPersonas,
    Chat,
    Collections,
    Personas,
    SigningOut,
    RfxChat,
    AdminSettings,
    UserUnauthorized,
    WillGenerator,
}

const App = () => {
    const [appState, setAppState] = useState(AppState.ProbeForBackend);

    const classes = useClasses();
    const dispatch = useAppDispatch();
    // TODO: Put this back in if you want an automatic redirect
    // useMsalAuthentication(InteractionType.Redirect);

    const { isAuthenticated, isAuthEnabled } = useAuth();
    const { logo, theme } = useTheme();
    const { instance, inProgress } = useMsal();
    const { isMaintenance } = useAppSelector((state: RootState) => state.app);
    const { selectedId } = useAppSelector((state: RootState) => state.collections);
    const { loadChats } = useChat();
    const { loadCollections } = useCollection();
    const { loadPersonas } = usePersona();
    const { getServiceInfo } = useServiceInfo();

    async function setUserProfilePhoto(activeUserInfo: ActiveUserInfo) {
        const accessToken = await TokenHelper.getGraphApiAccessTokenUsingMsal(instance);
        if (!accessToken) {
            return;
        }

        const graphResponse = await fetch('https://graph.microsoft.com/beta/me/photo/$value', {
            headers: { Authorization: `Bearer ${accessToken}` },
        });

        const pictureBlob = await graphResponse.blob();

        dispatch(
            setActiveUserInfo({
                ...activeUserInfo,
                photoUrl: URL.createObjectURL(pictureBlob),
            }),
        );
    }

    useEffect(() => {
        initializeIcons();
    }, []);

    useEffect(() => {
        if (isMaintenance && appState !== AppState.ProbeForBackend) {
            setAppState(AppState.ProbeForBackend);
            return;
        }

        if (isAuthenticated && appState === AppState.SettingUserInfo) {
            const account = instance.getActiveAccount();
            if (!account) {
                setAppState(AppState.ErrorLoadingUserInfo);
            } else {
                const activeUserInfo: ActiveUserInfo = {
                    id: `${account.localAccountId}.${account.tenantId}`,
                    email: account.username, // username is the email address
                    username: account.name ?? account.username,
                    photoUrl: undefined,
                };

                dispatch(setActiveUserInfo(activeUserInfo));

                void Promise.resolve(setUserProfilePhoto(activeUserInfo));

                setAppState(AppState.LoadingServiceInfo);
            }
        }

        // No auth alternative, set a default user
        if (!isAuthenticated && !isAuthEnabled && appState === AppState.SettingUserInfo) {
            dispatch(
                setActiveUserInfo({
                    id: 'id',
                    email: 'default@email.com', // username is the email address
                    username: 'Default',
                    photoUrl: undefined,
                }),
            );

            setAppState(AppState.LoadingServiceInfo);
        }

        if ((isAuthenticated || !isAuthEnabled) && appState === AppState.LoadingServiceInfo) {
            void Promise.all([
                // Load service information
                getServiceInfo()
                    .then((serviceInfo) => {
                        dispatch(setServiceInfo(serviceInfo));
                        setAppState(AppState.LoadingChats);
                    })
                    .catch((e: Error) => {
                        if (e.message.includes('Unauthorized')) {
                            setAppState(AppState.UserUnauthorized);
                        } else {
                            setAppState(AppState.ErrorLoadingServiceInfo);
                        }
                    }),
            ]);
        }

        if ((isAuthenticated || !isAuthEnabled) && appState === AppState.LoadingChats) {
            void Promise.all([
                // Load all chats into memory
                loadChats()
                    .then(() => {
                        setAppState(AppState.Chat);
                    })
                    .catch(() => {
                        setAppState(AppState.ErrorLoadingChats);
                    }),

                // TODO: Make this more efficient
                // Load all collections into memory
                loadCollections().catch(() => {
                    setAppState(AppState.ErrorLoadingCollections);
                }),

                // Load all available personas into memory
                loadPersonas().catch(() => {
                    setAppState(AppState.ErrorLoadingPersonas);
                }),
            ]);
        }

        if ((isAuthenticated || !isAuthEnabled) && appState === AppState.LoadingCollections) {
            if (selectedId) {
                setAppState(AppState.Collections);
            } else {
                void Promise.all([
                    // Load all collections into memory
                    loadCollections()
                        .then(() => {
                            setAppState(AppState.Collections);
                        })
                        .catch(() => {
                            setAppState(AppState.ErrorLoadingCollections);
                        }),
                ]);
            }
        }

        if ((isAuthenticated || !isAuthEnabled) && appState === AppState.LoadingPersonas) {
            void Promise.all([
                // Load all personas into memory
                loadPersonas()
                    .then(() => {
                        setAppState(AppState.Personas);
                    })
                    .catch(() => {
                        setAppState(AppState.ErrorLoadingPersonas);
                    }),
            ]);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [instance, inProgress, isAuthenticated, appState, isMaintenance]);

    const content = <Chat classes={classes} appState={appState} setAppState={setAppState} />;

    return (
        <FluentProvider className="app-container" theme={theme ?? undefined}>
            {isAuthEnabled ? (
                <>
                    <UnauthenticatedTemplate>
                        <div className={classes.container}>
                            <div className={classes.header}>
                                <div className={classes.headerTitle}>
                                    <Image src={logo} alt="[Arinco Logo]" height={34} />
                                </div>
                            </div>

                            {appState === AppState.SigningOut && <Loading text="Signing you out..." />}

                            {appState !== AppState.SigningOut && <Login />}
                        </div>
                    </UnauthenticatedTemplate>

                    <AuthenticatedTemplate>{content}</AuthenticatedTemplate>
                </>
            ) : (
                content
            )}
        </FluentProvider>
    );
};

const Chat = ({
    classes,
    appState,
    setAppState,
}: {
    classes: ReturnType<typeof useClasses>;
    appState: AppState;
    setAppState: (state: AppState) => void;
}) => {
    const { logo } = useTheme();
    const { token } = useAuth();

    const maintenanceService = useMemo(() => new ServiceInfoService(), []);

    useEffect(() => {
        if (token !== null) {
            void maintenanceService.retrieveServiceStatusAsync(token).then(() => {
                setAppState(AppState.SettingUserInfo);
            });
        }
    }, [token, maintenanceService, setAppState]);

    return (
        <div className={classes.container}>
            <div className={classes.header}>
                <div className={classes.headerTitle}>
                    <Image src={logo} alt="[Arinco Logo]" height={34} />
                    {process.env.REACT_APP_ENV !== 'production' && (
                        <h3 className={classes.environmentTag}>{process.env.REACT_APP_ENV?.toLocaleUpperCase()} APP</h3>
                    )}
                </div>

                <div className={classes.cornerItems}>
                    <div className={classes.cornerItems}>
                        <UserSettingsMenu
                            isCollectionsActive={appState === AppState.Collections}
                            setLoadingState={() => {
                                setAppState(AppState.SigningOut);
                            }}
                            setCollectionState={() => {
                                setAppState(AppState.LoadingCollections);
                            }}
                            setChatState={() => {
                                setAppState(AppState.Chat);
                            }}
                            setPersonaState={() => {
                                setAppState(AppState.LoadingPersonas);
                            }}
                            setRfxChatState={() => {
                                setAppState(AppState.RfxChat);
                            }}
                            setAdminSettingsState={() => {
                                setAppState(AppState.AdminSettings);
                            }}
                            setWillState={() => {
                                setAppState(AppState.WillGenerator);
                            }}
                        />
                    </div>
                </div>
            </div>

            {appState === AppState.ProbeForBackend && <BackendProbe />}

            {appState === AppState.SettingUserInfo && <BackendProbe />}

            {appState === AppState.ErrorLoadingUserInfo && (
                <Error text={'Unable to load user details. Please try signing out and signing back in.'} />
            )}

            {appState === AppState.ErrorLoadingChats && (
                <Error text={'Unable to load chats. Please try refreshing the page.'} />
            )}

            {appState === AppState.ErrorLoadingCollections && (
                <Error text={'Unable to load collections. Please try refreshing the page.'} />
            )}

            {appState === AppState.ErrorLoadingPersonas && (
                <Error text={'Unable to load personas. Please try refreshing the page.'} />
            )}

            {appState === AppState.ErrorLoadingServiceInfo && (
                <Error text={'Unable to load service information. Please try refreshing the page.'} />
            )}

            {appState === AppState.UserUnauthorized && (
                <Error text={'You have insufficient user role permissions. Please contact your app administrator.'} />
            )}

            {appState === AppState.LoadingServiceInfo && <Loading text="Loading..." />}

            {appState === AppState.LoadingChats && <Loading text="Loading chats..." />}

            {appState === AppState.LoadingCollections && <Loading text="Loading collections..." />}

            {appState === AppState.LoadingPersonas && <Loading text="Loading personas..." />}

            {appState === AppState.Chat && <ChatView />}

            {appState === AppState.Collections && <CollectionsView />}

            {appState === AppState.Personas && <PersonaView />}

            {appState === AppState.RfxChat && <RfxChatView />}

            {appState === AppState.AdminSettings && <AdminSettingsView />}

            {appState === AppState.WillGenerator && <WillsView />}
        </div>
    );
};

export default App;
