import botIcon1 from '../../assets/bot-icons/bot-icon-1.png';
import botIcon2 from '../../assets/bot-icons/bot-icon-2.png';
import botIcon3 from '../../assets/bot-icons/bot-icon-3.png';
import botIcon4 from '../../assets/bot-icons/bot-icon-4.png';
import botIcon5 from '../../assets/bot-icons/bot-icon-5.png';
import { useMsal } from '@azure/msal-react';
import { getErrorDetails } from '../../components/utils/TextUtils';
import { useAppDispatch, useAppSelector } from '../../redux/app/hooks';
import { addAlert } from '../../redux/features/app/appSlice';
import { IChatSessionState } from '../../redux/features/chats/ChatState';
import { AuthHelper } from '../auth/AuthHelper';
import { AlertType } from '../models/AlertType';
import { RfxService } from '../services/RfxService';
import {
    addRfxChat,
    deleteRfxChat,
    reloadRfxChat,
    setRfxChat,
    setRfxChats,
} from '../../redux/features/skills/skillsSlice';
import { RootState } from '../../redux/app/store';
import { useState } from 'react';
import { RfxChats } from '../../redux/features/skills/RfxState';
import { AskRfxChatRequirementsRequest, AskRfxChatResponsesRequest, ChatAuthorType, ChatSettings } from '../generated';

export interface GetResponseOptions {
    value: string;
    chatId: string;
}

export const useRfx = () => {
    const dispatch = useAppDispatch();

    const [isLoadingRfxRequirements, setIsLoadingRfxRequirements] = useState(false);
    const [isLoadingRfxResponses, setIsLoadingRfxResponses] = useState(false);

    const { instance, inProgress } = useMsal();
    const { chats } = useAppSelector((state: RootState) => state.skills.rfx);

    const chatService = new RfxService();

    const botProfilePictures: string[] = [botIcon1, botIcon2, botIcon3, botIcon4, botIcon5];

    const getBotProfilePicture = (index: number): string => {
        return botProfilePictures[index % botProfilePictures.length];
    };

    const getRfxRequirements = async (chatId: string, documentId: string) => {
        setIsLoadingRfxRequirements(true);

        const request: AskRfxChatRequirementsRequest = {
            documentId,
        };

        try {
            await chatService.getRfxChatRequirementsAsync(
                chatId,
                request,
                await AuthHelper.getBackendAccessToken(instance, inProgress),
            );

            await loadChat(chatId);
        } catch (e: any) {
            const errorDetails = getErrorDetails(e);
            if (errorDetails.includes('Failed to process plan')) {
                return undefined;
            }

            const action = 'generate bot response';
            const errorMessage = `Unable to ${action}. Details: ${getErrorDetails(e)}`;
            dispatch(addAlert({ message: errorMessage, type: AlertType.Error }));
        } finally {
            setIsLoadingRfxRequirements(false);
        }
    };

    const getRfxResponses = async (chatId: string, documentId: string, messageId: string, requirementIds: string[]) => {
        setIsLoadingRfxResponses(true);

        const request: AskRfxChatResponsesRequest = {
            documentId,
            messageId,
            rfxDocumentBreakdownRequirementIds: requirementIds,
        };

        try {
            await chatService.getRfxChatResponsesAsync(
                chatId,
                request,
                await AuthHelper.getBackendAccessToken(instance, inProgress),
            );

            await loadChat(chatId);
        } catch (e: any) {
            const errorDetails = getErrorDetails(e);
            if (errorDetails.includes('Failed to process plan')) {
                return;
            }

            const action = 'generate bot response';
            const errorMessage = `Unable to ${action}. Details: ${getErrorDetails(e)}`;
            dispatch(addAlert({ message: errorMessage, type: AlertType.Error }));
        } finally {
            setIsLoadingRfxResponses(false);
        }
    };

    const loadChat = async (chatId: string) => {
        try {
            const accessToken = await AuthHelper.getBackendAccessToken(instance, inProgress);
            const rfxProcessMessages = await chatService.getRfxProcessChatsAsync(chatId, accessToken);
            const rfxProcessResponseMessages = await chatService.getRfxProcessResponseChatsAsync(chatId, accessToken);
            dispatch(reloadRfxChat({ chatId, rfxProcessMessages, rfxProcessResponseMessages }));
            return true;
        } catch (e: any) {
            const errorMessage = `Unable to load chat. Details: ${getErrorDetails(e)}`;
            dispatch(addAlert({ message: errorMessage, type: AlertType.Error }));

            return false;
        }
    };

    const loadChats = async () => {
        try {
            const accessToken = await AuthHelper.getBackendAccessToken(instance, inProgress);
            const chatSessions = await chatService.getRfxChatsAsync(accessToken);

            if (chatSessions.length > 0) {
                const loadedConversations: RfxChats = {};
                for (const chatSession of chatSessions) {
                    const rfxProcessMessages = await chatService.getRfxProcessChatsAsync(chatSession.id, accessToken);
                    const rfxProcessResponseMessages = await chatService.getRfxProcessResponseChatsAsync(
                        chatSession.id,
                        accessToken,
                    );

                    loadedConversations[chatSession.id] = {
                        id: chatSession.id,
                        title: chatSession.title,
                        botProfilePicture: getBotProfilePicture(Object.keys(loadedConversations).length),
                        input: '',
                        rfxProcessingState: undefined,
                        rfxProcessMessages,
                        rfxProcessResponseMessages,
                        rfxDocumentInProgress: false,
                        botResponseStatus: undefined,
                        userDataLoaded: false,
                        settings: chatSession.settings,
                        disabled: false,
                        hidden: false,
                        lastUpdatedTimestamp: chatSession.dateModified,
                    };
                }

                dispatch(setRfxChats(loadedConversations));
            }

            if (chatSessions.length === 0) {
                await createChat('New Chat');
            }

            return true;
        } catch (e: any) {
            const errorMessage = `Unable to load chats. Details: ${getErrorDetails(e)}`;
            dispatch(addAlert({ message: errorMessage, type: AlertType.Error }));

            return false;
        }
    };

    const createChat = async (chatName: string) => {
        try {
            const accessToken = await AuthHelper.getBackendAccessToken(instance, inProgress);
            const chatSession = await chatService.createRfxChatAsync(accessToken, chatName);

            const conversation = {
                id: chatSession.id,
                title: chatSession.title,
                botProfilePicture: getBotProfilePicture(Object.keys(chats).length),
                input: '',
                rfxProcessingState: undefined,
                rfxProcessMessages: [],
                rfxProcessResponseMessages: [],
                rfxDocumentInProgress: false,
                botResponseStatus: undefined,
                userDataLoaded: false,
                settings: chatSession.settings,
                disabled: false,
                hidden: false,
                lastUpdatedTimestamp: chatSession.dateModified,
            };

            dispatch(addRfxChat(conversation));
            dispatch(setRfxChat(chatSession.id));
        } catch (e: any) {
            const errorMessage = `Unable to create chat. Details: ${getErrorDetails(e)}`;
            dispatch(addAlert({ message: errorMessage, type: AlertType.Error }));

            return '';
        }

        return '';
    };

    const deleteChat = async (chatId: string) => {
        try {
            const accessToken = await AuthHelper.getBackendAccessToken(instance, inProgress);
            await chatService.deleteRfxChatAsync(accessToken, chatId);
            dispatch(deleteRfxChat(chatId));
        } catch (e: any) {
            const errorMessage = `Unable to delete chat. Details: ${getErrorDetails(e)}`;
            dispatch(addAlert({ message: errorMessage, type: AlertType.Error }));
        }
    };

    const updateChat = async (chatId: string, chatName: string, settings: ChatSettings) => {
        try {
            const accessToken = await AuthHelper.getBackendAccessToken(instance, inProgress);
            await chatService.editRfxChatAsync(accessToken, chatId, chatName, settings);
        } catch (e: any) {
            const errorMessage = `Unable to update chat. Details: ${getErrorDetails(e)}`;
            dispatch(addAlert({ message: errorMessage, type: AlertType.Error }));
        }
    };

    return {
        loadChat,
        updateChat,
        getRfxRequirements,
        getRfxResponses,
        loadChats,
        deleteChat,
        createChat,
        isLoadingRfxRequirements,
        isLoadingRfxResponses,
    };
};

export function getFriendlyChatName(chatSession: IChatSessionState): string {
    const messages = chatSession.messages;

    // Regex to match the default message timestamp format that is used as the default chat name.
    // The format is: 'Chat Thread @ MM/DD/YYYY, hh:mm:ss AM/PM'.
    const autoGeneratedTitleRegex =
        /Chat Thread @ [0-9]{1,2}\/[0-9]{1,2}\/[0-9]{1,4}, [0-9]{1,2}:[0-9]{1,2}:[0-9]{1,2} [A,P]M/;

    const firstUserMessage = messages.find((message) => message.author !== ChatAuthorType.AGENT);

    // If the chat title is the default chat timestamp, use the first user message as the title.
    // If no user messages exist, use 'New Chat' as the title.
    const friendlyTitle = autoGeneratedTitleRegex.test(chatSession.title)
        ? (firstUserMessage?.userPrompt ?? 'New Chat')
        : chatSession.title;

    // Truncate the title if it is too long
    return friendlyTitle.length > 60 ? friendlyTitle.substring(0, 60) + '...' : friendlyTitle;
}
