/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CreateCollectionRequest } from '../models/CreateCollectionRequest';
import type { CreateCollectionResponse } from '../models/CreateCollectionResponse';
import type { DeleteDocumentJobCommandRequest } from '../models/DeleteDocumentJobCommandRequest';
import type { IndexDocumentJobCommandRequest } from '../models/IndexDocumentJobCommandRequest';
import type { ProcessDocumentJobCommand } from '../models/ProcessDocumentJobCommand';
import type { RetrieveBlobKeyForContentRequest } from '../models/RetrieveBlobKeyForContentRequest';
import type { RetrieveBlobKeyForContentResponse } from '../models/RetrieveBlobKeyForContentResponse';
import type { RetrieveBlobKeyForUploadRequest } from '../models/RetrieveBlobKeyForUploadRequest';
import type { RetrieveBlobKeyForUploadResponse } from '../models/RetrieveBlobKeyForUploadResponse';
import type { RetrieveCollectionResponse } from '../models/RetrieveCollectionResponse';
import type { RetrieveCollectionsResponse } from '../models/RetrieveCollectionsResponse';
import type { RetrieveDocumentsByCollectionIdResponse } from '../models/RetrieveDocumentsByCollectionIdResponse';
import type { UpdateCollectionRequest } from '../models/UpdateCollectionRequest';
import type { UploadDocumentsRequest } from '../models/UploadDocumentsRequest';
import type { UploadDocumentsResponse } from '../models/UploadDocumentsResponse';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class CollectionsApiService {
    /**
     * @param requestBody
     * @returns CreateCollectionResponse Success
     * @throws ApiError
     */
    public static createCollectionEndpoint(
        requestBody: CreateCollectionRequest,
    ): CancelablePromise<CreateCollectionResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/collections',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
            },
        });
    }
    /**
     * @returns RetrieveCollectionsResponse Success
     * @throws ApiError
     */
    public static retrieveCollectionsEndpoint(): CancelablePromise<RetrieveCollectionsResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/collections',
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
            },
        });
    }
    /**
     * @param id
     * @returns void
     * @throws ApiError
     */
    public static deleteCollectionEndpoint(
        id: string,
    ): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/collections/{id}',
            path: {
                'id': id,
            },
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
            },
        });
    }
    /**
     * @param id
     * @returns RetrieveCollectionResponse Success
     * @throws ApiError
     */
    public static retrieveCollectionEndpoint(
        id: string,
    ): CancelablePromise<RetrieveCollectionResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/collections/{id}',
            path: {
                'id': id,
            },
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
            },
        });
    }
    /**
     * @param id
     * @param requestBody
     * @returns void
     * @throws ApiError
     */
    public static updateCollectionEndpoint(
        id: string,
        requestBody: UpdateCollectionRequest,
    ): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/api/collections/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
            },
        });
    }
    /**
     * @param requestBody
     * @returns any Success
     * @throws ApiError
     */
    public static deleteDocumentCompletelyEndpoint(
        requestBody: DeleteDocumentJobCommandRequest,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/collections/DeleteDocument',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
            },
        });
    }
    /**
     * @param id
     * @param documentId
     * @returns void
     * @throws ApiError
     */
    public static deleteDocumentEndpoint(
        id: string,
        documentId: string,
    ): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/collections/{id}/Documents/{documentId}',
            path: {
                'id': id,
                'documentId': documentId,
            },
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
            },
        });
    }
    /**
     * @param requestBody
     * @returns any Success
     * @throws ApiError
     */
    public static indexDocumentEndpoint(
        requestBody: IndexDocumentJobCommandRequest,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/Collections/IndexDocument',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
            },
        });
    }
    /**
     * @param requestBody
     * @returns any Success
     * @throws ApiError
     */
    public static processDocumentEndpoint(
        requestBody: ProcessDocumentJobCommand,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/collections/ProcessDocument',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
            },
        });
    }
    /**
     * @param requestBody
     * @returns RetrieveBlobKeyForContentResponse Success
     * @throws ApiError
     */
    public static retrieveBlobKeyForContentEndpoint(
        requestBody: RetrieveBlobKeyForContentRequest,
    ): CancelablePromise<RetrieveBlobKeyForContentResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/collections/BlobKey/Content',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
            },
        });
    }
    /**
     * @param requestBody
     * @returns RetrieveBlobKeyForUploadResponse Success
     * @throws ApiError
     */
    public static retrieveBlobKeyForUploadEndpoint(
        requestBody: RetrieveBlobKeyForUploadRequest,
    ): CancelablePromise<RetrieveBlobKeyForUploadResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/collections/BlobKey/Upload',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
            },
        });
    }
    /**
     * @param id
     * @returns RetrieveDocumentsByCollectionIdResponse Success
     * @throws ApiError
     */
    public static retrieveDocumentsByCollectionIdEndpoint(
        id: string,
    ): CancelablePromise<RetrieveDocumentsByCollectionIdResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/collections/{id}/Documents',
            path: {
                'id': id,
            },
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
            },
        });
    }
    /**
     * @param id
     * @param formData
     * @returns UploadDocumentsResponse Success
     * @throws ApiError
     */
    public static uploadDocumentsEndpoint(
        id: string,
        formData: UploadDocumentsRequest,
    ): CancelablePromise<UploadDocumentsResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/collections/{id}/Documents',
            path: {
                'id': id,
            },
            formData: formData,
            mediaType: 'multipart/form-data',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
            },
        });
    }
    /**
     * @returns any Success
     * @throws ApiError
     */
    public static updateDocumentStatusEndpoint(): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/collections/SyncStatus',
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
            },
        });
    }
}
