import { Body1, Divider, makeStyles, mergeClasses, shorthands, Text, tokens } from '@fluentui/react-components';
import { Brands, BrandVariants } from '@fluentui/tokens';
import { TokenUsageFunctionNameMap, TokenUsageView, ITokenUsageViewDetails } from '../../libs/models/TokenUsage';
import { useAppSelector } from '../../redux/app/hooks';
import { RootState } from '../../redux/app/store';
import { TypingIndicator } from '../chat/typing-indicator/TypingIndicator';
import { TokenUsageBar } from './TokenUsageBar';
import { TokenUsageLegendItem } from './TokenUsageLegendItem';

const useClasses = makeStyles({
    horizontal: {
        display: 'flex',
        ...shorthands.gap(tokens.spacingVerticalSNudge),
        alignItems: 'center',
    },
    content: {
        display: 'flex',
        flexDirection: 'column',
        ...shorthands.gap(tokens.spacingHorizontalS),
        paddingBottom: tokens.spacingHorizontalM,
    },
    popover: {
        width: '300px',
    },
    header: {
        marginBlockEnd: tokens.spacingHorizontalM,
    },
    legend: {
        'flex-flow': 'wrap',
    },
    divider: {
        width: '97%',
    },
});

interface ITokenUsageGraph {
    promptView?: boolean;
}

export const TokenUsageGraph: React.FC<ITokenUsageGraph> = ({ promptView }) => {
    const classes = useClasses();

    const { chats, selectedId } = useAppSelector((state: RootState) => state.chat);
    const { tokenUsage, serviceInfo } = useAppSelector((state: RootState) => state.app);

    const loadingResponse =
        selectedId !== '' && chats[selectedId].botResponseStatus && Object.entries(tokenUsage).length === 0;
    const semanticKernelBrandRamp = serviceInfo.settings.colors as BrandVariants;

    const promptInputView: TokenUsageView = {};
    const promptOutputView: TokenUsageView = {};
    const userIntentExtractionView: TokenUsageView = {};
    let promptInputUsage = 0;
    let promptOutputUsage = 0;
    let userIntentExtractionUsage = 0;

    // Color index of semanticKernelBrandRamp array defined in styles.ts
    const graphColors = {
        brand: {
            legend: 40 as Brands,
            index: 40 as Brands,
        },
        contrast: {
            legend: 100 as Brands,
            index: 100 as Brands,
        },
        other: {
            legend: 160 as Brands,
            index: 160 as Brands,
        },
    };

    Object.entries(tokenUsage).forEach(([key, value]) => {
        if (value && value > 0) {
            const viewDetails: ITokenUsageViewDetails = {
                usageCount: value,
                legendLabel: TokenUsageFunctionNameMap[key],
                color: semanticKernelBrandRamp[graphColors.brand.index],
            };

            if (key.includes('promptInput')) {
                promptInputUsage += value;
                viewDetails.color = semanticKernelBrandRamp[graphColors.brand.index];
                promptInputView[key] = viewDetails;
            } else if (key.includes('promptOutput')) {
                promptOutputUsage += value;
                viewDetails.color = semanticKernelBrandRamp[graphColors.contrast.index];
                promptOutputView[key] = viewDetails;
            } else if (key.includes('userIntentExtraction')) {
                userIntentExtractionUsage += value;
                viewDetails.color = semanticKernelBrandRamp[graphColors.other.index];
                userIntentExtractionView[key] = viewDetails;
            }
        }
    });

    const totalUsage = promptInputUsage + promptOutputUsage + userIntentExtractionUsage;

    return (
        <>
            <h3 className={classes.header}>Token Usage</h3>
            <div className={classes.content}>
                {loadingResponse ? (
                    <Body1>
                        Final token usage will be available once bot response is generated.
                        <TypingIndicator />
                    </Body1>
                ) : (
                    <>
                        {totalUsage > 0 ? (
                            <>
                                {!promptView && <Text>Total token usage for current session</Text>}
                                <div className={classes.horizontal} style={{ gap: tokens.spacingHorizontalXXS }}>
                                    {Object.entries(promptInputView).map(([key, details]) => {
                                        return <TokenUsageBar key={key} details={details} totalUsage={totalUsage} />;
                                    })}
                                    {Object.entries(promptOutputView).map(([key, details]) => {
                                        return <TokenUsageBar key={key} details={details} totalUsage={totalUsage} />;
                                    })}
                                    {Object.entries(userIntentExtractionView).map(([key, details]) => {
                                        return <TokenUsageBar key={key} details={details} totalUsage={totalUsage} />;
                                    })}
                                </div>
                                <div className={mergeClasses(classes.legend, classes.horizontal)}>
                                    <TokenUsageLegendItem
                                        key={'Prompt Input'}
                                        name={'Prompt Input'}
                                        usageCount={promptInputUsage}
                                        items={Object.values(promptInputView)}
                                        color={semanticKernelBrandRamp[graphColors.brand.legend]}
                                    />
                                    <TokenUsageLegendItem
                                        key={'Prompt Output'}
                                        name={'Prompt Output'}
                                        usageCount={promptOutputUsage}
                                        items={Object.values(promptOutputView)}
                                        color={semanticKernelBrandRamp[graphColors.contrast.legend]}
                                    />
                                    <TokenUsageLegendItem
                                        key={'User Intent Extraction'}
                                        name={'User Intent Extraction'}
                                        usageCount={userIntentExtractionUsage}
                                        items={Object.values(userIntentExtractionView)}
                                        color={semanticKernelBrandRamp[graphColors.other.legend]}
                                    />
                                </div>
                            </>
                        ) : promptView ? (
                            <Text>No tokens were used. This is either a hardcoded response or saved plan.</Text>
                        ) : (
                            <Text>No tokens have been used in this session yet.</Text>
                        )}
                    </>
                )}
            </div>
            <Divider className={classes.divider} />
        </>
    );
};
