export const COPY = {
    CHAT_DELETED_MESSAGE: (chatName?: string) =>
        `Chat ${
            chatName ? `{${chatName}} ` : ''
        }has been removed. You can still access the latest chat history for now. All chat content will be cleared once you refresh or exit the application.`,
    COLLECTION_DELETED_MESSAGE: (collectionName?: string) =>
        `Collection ${
            collectionName ? `{${collectionName}} ` : ''
        }has been removed. You can still access the latest collection history for now. All collection documents will be cleared once you refresh or exit the application.`,
    REFRESH_APP_ADVISORY: 'Please refresh the page to ensure you have the latest data.',
};
