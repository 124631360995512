import { BaseService } from './BaseService';
import { CreateTemplateRequest, CreateTemplateResponse, TemplateGeneratorApiService } from '../generated';

export class TemplateService extends BaseService {
    generateTemplateAsync = async (
        body: CreateTemplateRequest,
        accessToken: string,
    ): Promise<CreateTemplateResponse> => {
        this.setAccessToken(accessToken);

        try {
            return await TemplateGeneratorApiService.createTemplateEndpoint(body);
        } catch (err) {
            throw Object.assign(this.processErrorMessage(err));
        }
    };
}
