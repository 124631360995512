/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import {
    AskRfxChatResponse,
    AskRfxChatRequirementsRequest,
    AskRfxChatRequirementsResponse,
    AskRfxChatResponsesRequest,
    ChatSettings,
    RfxApiService,
    RfxChatMessageHeader,
    RfxChatSessionHeader,
} from '../generated';
import { BaseService } from './BaseService';

export class RfxService extends BaseService {
    public getRfxProcessChatsAsync = async (chatId: string, accessToken: string): Promise<RfxChatMessageHeader[]> => {
        this.setAccessToken(accessToken);

        try {
            const result = await RfxApiService.getRfxProcessChatMessagesEndpoint(chatId);
            return result.results;
        } catch (err) {
            throw Object.assign(this.processErrorMessage(err));
        }
    };

    public getRfxProcessResponseChatsAsync = async (
        chatId: string,
        accessToken: string,
    ): Promise<RfxChatMessageHeader[]> => {
        this.setAccessToken(accessToken);

        try {
            const result = await RfxApiService.getRfxProcessResponseChatMessagesEndpoint(chatId);
            return result.results;
        } catch (err) {
            throw Object.assign(this.processErrorMessage(err));
        }
    };

    public getRfxChatRequirementsAsync = async (
        chatId: string,
        chatRequest: AskRfxChatRequirementsRequest,
        accessToken: string,
    ): Promise<AskRfxChatRequirementsResponse> => {
        this.setAccessToken(accessToken);

        try {
            return await RfxApiService.askRfxChatRequirementsEndpoint(chatId, chatRequest);
        } catch (err) {
            throw Object.assign(this.processErrorMessage(err));
        }
    };

    public getRfxChatResponsesAsync = async (
        chatId: string,
        request: AskRfxChatResponsesRequest,
        accessToken: string,
    ): Promise<AskRfxChatResponse[]> => {
        this.setAccessToken(accessToken);

        try {
            const result = await RfxApiService.askRfxChatResponsesEndpoint(chatId, request);
            return result.results;
        } catch (err) {
            throw Object.assign(this.processErrorMessage(err));
        }
    };

    public getRfxChatsAsync = async (accessToken: string): Promise<RfxChatSessionHeader[]> => {
        this.setAccessToken(accessToken);

        try {
            const result = await RfxApiService.getRfxChatsEndpoint();
            return result.results;
        } catch (err) {
            throw Object.assign(this.processErrorMessage(err));
        }
    };

    public createRfxChatAsync = async (accessToken: string, chatName: string): Promise<RfxChatSessionHeader> => {
        this.setAccessToken(accessToken);

        try {
            const result = await RfxApiService.createRfxChatEndpoint({ name: chatName });
            return result.chat;
        } catch (err) {
            throw Object.assign(this.processErrorMessage(err));
        }
    };

    public deleteRfxChatAsync = async (accessToken: string, chatId: string): Promise<void> => {
        this.setAccessToken(accessToken);

        try {
            await RfxApiService.deleteRfxChatEndpoint(chatId);
        } catch (err) {
            throw Object.assign(this.processErrorMessage(err));
        }
    };

    public editRfxChatAsync = async (
        accessToken: string,
        chatId: string,
        chatName: string,
        settings: ChatSettings,
    ): Promise<void> => {
        this.setAccessToken(accessToken);

        try {
            await RfxApiService.editRfxChatEndpoint(chatId, { name: chatName, chatSettings: settings });
        } catch (err) {
            throw Object.assign(this.processErrorMessage(err));
        }
    };
}
