import { makeStyles, tokens, shorthands } from '@fluentui/react-components';
import React, { useEffect, useReducer, useState } from 'react';
import DropdownTreeSelect, { TreeNode } from 'react-dropdown-tree-select';
import { useTree } from '../../libs/hooks/useTree';
import { useRfx } from '../../libs/hooks/useRfx';
import { ChatSettings } from '../../libs/generated';
import { useAppDispatch, useAppSelector } from '../../redux/app/hooks';
import { RootState } from '../../redux/app/store';
import { editRfxChat } from '../../redux/features/skills/skillsSlice';

const useClasses = makeStyles({
    root: {
        minWidth: '300px',
        borderLeft: '1px solid rgba(0, 0, 0, 0.1)',
        maxHeight: '',
    },
    tabPanelCustom: {
        maxHeight: '100px',
    },
    drawerLabel: {
        fontWeight: 600,
        margin: 0,
    },
    drawerSubLabel: {
        fontWeight: 400,
        margin: 0,
        marginTop: '-10px',
        color: 'rgba(0, 0, 0, 0.6)',
    },
    drawerHeaderText: {
        display: 'flex',
        alignItems: 'center',
        gap: tokens.spacingHorizontalS,
    },
    drawerHeader: {
        paddingTop: tokens.spacingVerticalM,
    },
    divider: {
        ...shorthands.margin(tokens.spacingVerticalM, tokens.spacingHorizontalNone),
    },
    emptySpace: {
        paddingTop: tokens.spacingHorizontalXL,
        width: '100%',
    },
    control: {
        width: '97%',
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        gap: tokens.spacingHorizontalMNudge,
    },
    errorText: {
        color: 'red',
        fontSize: '14px',
        fontWeight: 600,
        margin: 0,
    },
});

export const RfxDrawer: React.FC = () => {
    const [selectedDocuments, setSelectedDocuments] = useState<string[]>([]);
    const [update, forceUpdate] = useReducer((x: number) => x + 1, 0);

    const { chats: conversations, selectedId } = useAppSelector((state: RootState) => state.skills.rfx);
    const { allCollections, settings } = useTree({ selectedDocumentNodes: selectedDocuments, update });
    const { collections } = useAppSelector((state: RootState) => state.collections);

    const dispatch = useAppDispatch();
    const classes = useClasses();
    const rfx = useRfx();
    const conversation = Object.entries(conversations).find((x) => x[0] === selectedId)?.[1];

    useEffect(() => {
        if (!conversation) {
            return;
        }

        const collectionIds = conversation.settings.collectionIds.map((collectionId) => `collection..${collectionId}`);

        const documentIds = conversation.settings.documentIds.map((documentId) => {
            let collectionId = '';
            Object.values(collections).forEach((c) => {
                const matchingDocument = c.documents.find((x) => x.id === documentId);
                if (matchingDocument) {
                    collectionId = matchingDocument.collectionId;
                }
            });
            return `document..${documentId}..${collectionId}`;
        });

        setSelectedDocuments([...collectionIds, ...documentIds]);
        forceUpdate();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedId]);

    useEffect(() => {
        if (!conversation) {
            return;
        }

        const newSettings: ChatSettings = {
            ...settings,
            personaId: conversation.settings.personaId,
        };

        void rfx.updateChat(conversation.id, conversation.title, newSettings);
        dispatch(editRfxChat({ id: conversation.id, newTitle: conversation.title, newSettings }));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [settings]);

    if (!conversation) {
        return <div className={classes.emptySpace}></div>;
    }

    const changeDocuments = (_currentNode: TreeNode, selectedNodes: TreeNode[]) => {
        setSelectedDocuments(selectedNodes.map((x) => x.value));
    };

    return (
        <>
            <div className={classes.tabPanelCustom} role="tabpanel" aria-labelledby="RfxDrawer">
                <div className={classes.control}>
                    <p className={classes.drawerLabel}>RFX Additional Documents</p>
                    <p className={classes.drawerSubLabel}>One or more documents are required</p>

                    <DropdownTreeSelect
                        texts={{ placeholder: 'Select additional documents...' }}
                        data={allCollections}
                        onChange={changeDocuments}
                        keepOpenOnSelect={true}
                    />
                </div>
            </div>
        </>
    );
};
