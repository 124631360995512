import {
    CreatePersonaRequest,
    CreatePersonaResponse,
    PersonaApiService,
    RetrievePersonasResponse,
    UpdatePersonaRequest,
    UpdatePersonaResponse,
} from '../generated';
import { BaseService } from './BaseService';

export class PersonaService extends BaseService {
    public retrievePersonasAsync = async (accessToken: string): Promise<RetrievePersonasResponse> => {
        this.setAccessToken(accessToken);

        try {
            return await PersonaApiService.retrievePersonasEndpoint();
        } catch (err) {
            throw Object.assign(this.processErrorMessage(err));
        }
    };

    public createPersonaAsync = async (
        name: string,
        description: string,
        message: string,
        requiresDocuments: boolean,
        accessToken: string,
    ): Promise<CreatePersonaResponse> => {
        this.setAccessToken(accessToken);

        const request: CreatePersonaRequest = {
            name: name,
            description: description,
            message: message,
            requiresDocuments: requiresDocuments,
        };

        try {
            return await PersonaApiService.createPersonaEndpoint(request);
        } catch (err) {
            throw Object.assign(this.processErrorMessage(err));
        }
    };

    public updatePersonaAsync = async (
        personaId: string,
        name: string,
        description: string,
        message: string,
        requiresDocuments: boolean,
        accessToken: string,
    ): Promise<UpdatePersonaResponse> => {
        this.setAccessToken(accessToken);

        const request: UpdatePersonaRequest = {
            name: name,
            description: description,
            message: message,
            requiresDocuments: requiresDocuments,
        };

        try {
            return await PersonaApiService.updatePersonaEndpoint(personaId, request);
        } catch (err) {
            throw Object.assign(this.processErrorMessage(err));
        }
    };

    public deletePersonaAsync = async (personaId: string, accessToken: string): Promise<void> => {
        this.setAccessToken(accessToken);

        try {
            return PersonaApiService.deletePersonaEndpoint(personaId);
        } catch (err) {
            throw Object.assign(this.processErrorMessage(err));
        }
    };

    public setPersonaAsDefaultAsync = async (personaId: string, accessToken: string): Promise<void> => {
        this.setAccessToken(accessToken);

        try {
            return PersonaApiService.setPersonaAsDefaultEndpoint(personaId);
        } catch (err) {
            throw Object.assign(this.processErrorMessage(err));
        }
    };
}
