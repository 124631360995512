import { IRfxState } from './RfxState';

export interface ISkillsState {
    rfx: IRfxState;
}

export const initialState: ISkillsState = {
    rfx: {
        chats: {},
        selectedId: '',
    },
};
