import { useIsAuthenticated, useMsal } from '@azure/msal-react';
import { useEffect, useMemo, useState } from 'react';
import { AuthHelper } from '../auth/AuthHelper';
import { useAppSelector } from '../../redux/app/hooks';

export const useAuth = () => {
    const [token, setToken] = useState<string | null>(null);

    const { instance, inProgress } = useMsal();
    const { authConfig } = useAppSelector((state) => state.app);

    const isAuthenticated = useIsAuthenticated();

    const isAuthEnabled = useMemo(() => AuthHelper.isAuthEnabled(authConfig), [authConfig]);

    useEffect(() => {
        const fetchToken = async () => {
            try {
                const fetchedToken = await AuthHelper.getBackendAccessToken(instance, inProgress);
                setToken(fetchedToken);
            } catch (e) {
                setToken(null);
            }
        };

        void fetchToken();
    }, [instance, inProgress]);

    return {
        token,
        isAuthEnabled,
        isAuthenticated,
    };
};
