import { PayloadAction } from '@reduxjs/toolkit';
import { ISkillsState } from './SkillsState';
import { RfxChats, IRfxChatState } from './RfxState';
import { ChatSettings, RfxChatMessageHeader } from '../../../libs/generated';

export interface IReloadRfxChatPayload {
    chatId: string;
    rfxProcessMessages: RfxChatMessageHeader[];
    rfxProcessResponseMessages: RfxChatMessageHeader[];
}

export const rfxReducers = {
    reloadRfxChat: (state: ISkillsState, action: PayloadAction<IReloadRfxChatPayload>) => {
        state.rfx.chats = {
            ...state.rfx.chats,
            [action.payload.chatId]: {
                ...state.rfx.chats[action.payload.chatId],
                rfxProcessMessages: action.payload.rfxProcessMessages,
                rfxProcessResponseMessages: action.payload.rfxProcessResponseMessages,
            },
        };
    },
    setRfxChats: (state: ISkillsState, action: PayloadAction<RfxChats>) => {
        state.rfx.chats = { ...action.payload };
    },
    setRfxChat: (state: ISkillsState, action: PayloadAction<string>) => {
        state.rfx.selectedId = action.payload;
    },
    addRfxChat: (state: ISkillsState, action: PayloadAction<IRfxChatState>) => {
        state.rfx.chats = { ...state.rfx.chats, [action.payload.id]: action.payload };
    },
    deleteRfxChat: (state: ISkillsState, action: PayloadAction<string>) => {
        const chats = Object.keys(state.rfx.chats)
            .filter((key) => key !== action.payload)
            .reduce<RfxChats>((obj, key) => {
                obj[key] = state.rfx.chats[key];
                return obj;
            }, {});

        state.rfx.chats = { ...chats };

        if (state.rfx.selectedId === action.payload && Object.keys(state.rfx.chats).length > 0) {
            state.rfx.selectedId = Object.keys(state.rfx.chats)[0];
        }

        if (state.rfx.selectedId === action.payload && Object.keys(state.rfx.chats).length === 0) {
            state.rfx.selectedId = '';
        }
    },
    editRfxChat: (
        state: ISkillsState,
        action: PayloadAction<{ id: string; newTitle: string; newSettings: ChatSettings }>,
    ) => {
        state.rfx.chats = {
            ...state.rfx.chats,
            [action.payload.id]: {
                ...state.rfx.chats[action.payload.id],
                title: action.payload.newTitle,
                settings: action.payload.newSettings,
            },
        };
    },
};

export const rfxActions = {
    ...rfxReducers,
};
